.group-settings{
    background-color: var(--ion-color-light-shade);
    border-radius: var(--ion-radius);
    margin: .5rem;
    margin-bottom: 1rem;
}
.group-settings ion-item{
    border-bottom: 1px solid rgba(var(--ion-color-medium-rgb), .1);
    font-size: smaller !important;
}
 
audio#play-control::-webkit-media-controls-panel {
    background-color: transparent !important;
}