.container-devices-table{
    height: 83vh;
    overflow-y: scroll;
}
.devices-table{
    width: 100% ;
    font-size: .8em ; 
    position: relative;
}
 
.row-header th{  
   background-color: var(--ion-color-light-tint); 
   margin-right:  .5rem;
   padding: 0px;
   border: 1px solid var(--ion-color-light-shade); 
} 
.row-record{
    border-bottom: 1px solid var(--ion-color-light-shade); 
}
.row-record td{
    border-left: 1px solid var(--ion-color-light-shade); 
    border-right: 1px solid var(--ion-color-light-shade); 
    padding-left: .5rem;
}