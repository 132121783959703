.bg-shade{ 
  background-color: var(--ion-color-light-shade); 
}
.flex-center{
  display: flex;
  align-items: center;
}
.dialog-add-employee{
    --min-height: 80vh;
}

.page-container .top-container, 
.page-container .content{
    background-color: var(--ion-color-light); 
    width: 93.5vw ;
    margin-left: 1vw  ;
    border-bottom: none;
}
.top-container{  margin: 0px !important ;}

.page-container .content{
    margin-top: .5rem;
    min-height: 15rem;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
}
.page-container .content table{ width: 100%; }
.page-container .content tr{
    border-bottom: 1px solid #DDD;
}


.border-bottom{
    border-bottom: 1px solid var(--ion-color-light-shade);
}


ion-radio::part(container) {
    width: 20px;
    height: 20px;
  
    border-radius: 5px;
    border: 2px solid #ddd;
  }
  
  ion-radio::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;
  }
  
  ion-radio.radio-checked::part(container) {
    background:var(--ion-color-primary);
    border-color: transparent;
  }
  
  ion-radio.radio-checked::part(mark) {
    width: 6px;
    height: 10px;
  
    border-width: 0px 2px 2px 0px;
    border-style: solid;
    border-color: #fff;
  
    transform: rotate(45deg);
  }
  
  
    .radio-checked.ios {
      border-color: var(--ion-color-primary); 
    }




.choose-file label {
  border: 1px solid  var(--ion-color-primary); 
  color: var(--ion-color-primary);
  padding: 0.25rem 1rem 0.25rem 1rem; 
  border-radius: 0.4rem;
  cursor: pointer; 
  margin: 0px;
  margin-top: 5px;
  margin-right:  1.5rem;
  font-size: .9em;
  width: 10rem;
}
.choose-file label:hover{
  border: 1px solid  var(--ion-color-primary-tint); 
  color: var(--ion-color-primary-tint);
}