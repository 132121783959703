.title{
    font-size: 1.5em;
    font-weight: bold;
    margin-right: 2rem;
    color: rgba(var(--ion-color-dark-rgb), .9);
}

.geozones-list{
    width:100%;
    height:60vh;
    overflow-y:scroll; 
}

.geozones-list  ion-checkbox{
    --size: 15px;
    --background-checked: var(--ion-color-primary);
}
.geozones-list ion-item ion-label{
    font-size: .8em !important;
}

.input-location{
    width: 20rem;
    min-height:10rem;
    position:absolute;
    z-index: 9999;
    top:0;
    left: 35vw;
    background: var(--ion-color-light);
    border-radius: var(--ion-radius);
    padding: .5rem; 
    box-shadow: var(--ion-box-shadow);
    font-size: small;
}
.input-location .title-toolbar{
    font-size: medium;
    background-color: rgba( var(--ion-color-medium-rgb), .8);
    border-radius: 5px;
    font-weight: bold;
    color: var(--ion-color-light);
}

.button-start-draw{
    width: 3rem;
    height: 3rem;
    background-color: var(--ion-color-light);
    color: var(--ion-color-medium);
    font-size: 1.3em;
    top: 5rem;
    right: 2rem;
    z-index: 999;
    position: absolute;
    border-radius: 5px !important;
    border: 2px solid rgba(var(--ion-color-dark-rgb) , .3);
}

#dott-sticky {
    z-index: 9999 !important;
    position: fixed; /* Floating above */ 
    pointer-events: none; /* Allow clicking trough the div */
    cursor: pointer;
    width: .6rem; 
    height: .6rem; 
    border-radius: 50%;
    border: 2px solid var(--ion-color-primary);
    background-color: rgb(var(--ion-color-primary-rgb),.4);
  }
  
.geofences-content{
    background-color: var(--ion-color-light);
    height: 98vh;
}

.add-geofences .input{
    background-color: var(--ion-color-light-shade); 
    padding-left: .5rem;
    height: 2.5rem !important;
}
.add-geofences .input ion-input{
    height: 100% !important;
    font-size: .8em !important;
}
.geozone-segment ion-segment-button{
    padding: 2px !important;
}
.modal-save-geozone{
    --height: 99vh;
    --width: 99vw;
}
.geo-icon-choice{
    width: 1.7rem; 
    background-color: transparent !important;
    border: 1px solid  transparent;
    border-radius: 15px;
}
 
ion-popover.popover-find-location{

    --width: 18rem;
}
 
.geozone-list-scroll::-webkit-scrollbar {
    width: 10px !important;
    background:  var(--ion-color-light-shade)  ; 
    border-radius: none;
    opacity: .5;
  }
.geozone-list-scroll::-webkit-scrollbar {
    width: 3px;
    border-radius: 15px;
} 
/* Track */
.geozone-list-scroll::-webkit-scrollbar-track {
    background:  var(--ion-color-light-shade)  ; 
} 
/* Handle */
.geozone-list-scroll::-webkit-scrollbar-thumb {
    /* background: rgba( var(--ion-color-primary-rgb), .3) ; */
    background: var(--ion-color-primary);
    border-radius: 5px;
    cursor: pointer;
}