ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header, ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
} 

ion-menu.menu {
 width: fit-content
}
.popup-menu-notification::part(content){
  --width: 30rem;
  /* height: 60vh; */
}
.popup-menu-notification  .popover-arrow::after{
  /* margin-top: -50% !important; */
  /* top: calc(  var(--offset-y, 0)) !important; 
  left: calc(49.578125px + var(--offset-x, 0)) !important; */
}
.popup-menu-notification .message-container{
  height: 55vh  ;
  overflow-y: scroll !important;
}
.popup-menu-notification .message-container::-webkit-scrollbar {
  width: 10px !important;
}
.popup-menu-notification .message-container::-webkit-scrollbar {
  width: 3px;
  border-radius: 15px;
} 
/* Track */
.popup-menu-notification .message-container::-webkit-scrollbar-track {
  background:  var(--ion-color-light) ;
  border-radius: 15px;
} 
/* Handle */
.popup-menu-notification .message-container::-webkit-scrollbar-thumb {
  background: rgba( var(--ion-color-primary-rgb), .3) ;
}
.notify-avatar{
  --border-radius: 50%;
  background-color: var(--ion-color-light-shade);
  margin-right: .5rem;
}
.notify-avatar ion-badge{
  position:absolute;
  font-size:.5em;
  top:-.5rem;
}
 