.devider{
    width: 100%;
    border-top: 1px solid #DDD;
    margin: .5rem 0 .5rem;
}
.playback-content{
    width: 100% ;
    height: 85vh ;
    background-color: var(--ion-color-light) ;
    padding: 1rem ;
    border-radius: 15px ;
    border: 1px solid #EEE ;
}
.datetime{
    background-color: var(--ion-color-light-shade) ;
    margin-top: 1rem;
    border-radius: 5px;
    font-size: .8em !important;
    padding: .2rem;
}

button.button-search{
    width: 100%;
    padding: .5rem;
    background-color: var(--ion-color-primary);
    margin-top: 1rem;
    border-radius: 10px;
}
.device-channel{
    width: 100%;
    padding-left:1rem;
    background-color: var(--ion-color-light-shade);
    border-radius: 15px;
    border-left: 1px solid  var(--ion-color-light-shade);
    border-right:  1px solid  var(--ion-color-light-shade);
    border-bottom:  1px solid  var(--ion-color-light-shade);
}
.device-channel .channel-item{
    --background: transparent ;
}

#realtime-iframe{
    width: 100% ;
    height: 70vh;
    border: none ;
    max-width: 1220px ;
}

.table-files{
    background-color: var(--ion-color-light-shade);
}
.table-files .row-header .cell-header{
    display: flex;
    align-items: flex-start;
}
.table-files .row-header {
    border-bottom: 1px solid var(--ion-color-dark);
}


.playfile-timeline{

}


@media only screen and (max-width: 760px) {
    #realtime-iframe{
        height: 15rem;
    }
    .playback-content{
        width: 100% ;
        height: fit-content !important ; 
    }
}