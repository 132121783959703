
.widget-map{
    min-height: 16rem !important;
}
#dashboard-map{
    height: 16rem !important;
}
.widget-filter-deivice { width: fit-content;}
.widget-filter-deivice ion-label{
    font-size: .8em !important;
}
.close-popup-icon{
    position: absolute;
    z-index: 999;
    right: .5rem;
    top: .5rem;
    color: var(--ion-color-primary);
    cursor: pointer;
}

.event-list-container{
    height: 40vh; 
    overflow-y: scroll;
    background-color: var(--ion-color-light-tint);
    padding-top: 2em;
    padding: .8em;
    margin-top: 1rem;
    border-radius: 10px;
}
.event-list{
    position: relative;
    background-color: var(--ion-color-light-shade); 
    padding: 1rem .2rem .2rem .2rem ;
    margin-bottom: 1.5rem;
    padding-left: .5rem;
    border-radius: 10px;
}

.event-list .title-time{
    position: absolute;
    top: -.7rem;
    background-color: #FFF;
    border: 1px solid #F8BCA9;
    color: #F8BCA9;
    font-weight: bold;
    padding: .2rem;
    border-radius: 5px;
    font-size: .7em;
}

.event-list .vehicle{
    font-size: .8em;
    font-weight: bold;
}
.event-list .address{
    font-size: .7em;
}
.input-widget {
    padding: .5rem;
    background-color: var(--ion-color-light-shade);
    margin-bottom: .5rem;
    
    border-radius: 10px;
}
.input-widget ion-label{
    font-size: .8em !important;
}
.input-widget ion-icon{
    float: right;
    margin-top: .2rem;
    margin-right: .5rem;
}


.input-widget.light{
    background-color: var(--ion-color-light-tint);
}
.input-widget.light  ion-label{  
    color: var(--ion-color-dark);
    font-weight: bold;
    font-size: 1em !important;
}

.widget-events{
    padding: .5rem;
}