.device-summary-popup{
    width:  17rem  ;
    position: fixed ;
    z-index: 9998;
    height: 100vh ;
    overflow-y: scroll !important;
    background: var(--ion-color-light) ;
    right: 0 ;
    top: 0 ;
    font-size: .9em ;
    color: var(--ion-color-dark);
}
.row-direction{
    cursor:pointer
}
.row-direction:hover{
    background-color: var(--ion-color-light-tint);
}
.device-modal-tabs { width: 100%; }
.home-screen-tabs { padding-left: .5rem; }
.home-screen-tabs ,.mdvr-screen-tabs {  height: 80vh; }
.home-screen-tabs ul  , .mdvr-screen-tabs ul  , .signin-screen-tabs ul { 
    background: var(--ion-color-light);
    background: linear-gradient(8deg, rgba(var(--ion-color-medium),1) 0%,   var(--ion-color-light-shade) 25%, rgba(var(--ion-color-light-rgb),1) 65%);
}

.home-screen-tabs ul li , .mdvr-screen-tabs ul li  , .signin-screen-tabs ul li{
     min-width: 3rem;
     color: var(--ion-color-dark);
     background: var(--ion-color-light);
     text-align: center;
}
.home-screen-tabs ul li[aria-selected="false"] , .mdvr-screen-tabs ul li[aria-selected="false"]  , .signin-screen-tabs ul li[aria-selected="false"] { 
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--ion-color-light); 
    border-bottom: 1px solid var(--ion-color-light-shade); 
     /*border-left: 1px solid var(--ion-color-light-shade); 
    border-right: 1px solid var(--ion-color-light-shade);  */
    margin-left: -.3rem;  
    box-shadow: 13px -2px 7px -8px rgba(0,0,0,0.28);
    /* border-right: 1px solid var(--ion-color-medium); */
    background: linear-gradient(90deg, rgba(var(--ion-color-medium-rgb), .2) 5%,  var(--ion-color-light-tint) 80%);
    /* margin-bottom: 2%; */
} 
.home-screen-tabs ul li[aria-selected="false"] ion-icon{
    color: var(--ion-color-medium);
}
.home-screen-tabs ul li[aria-selected="true"], .mdvr-screen-tabs ul li[aria-selected="true"]  , .signin-screen-tabs ul li[aria-selected="true"] {
    -webkit-box-shadow: 4px -7px 8px -7px rgba(0,0,0,0.20);
    -moz-box-shadow: 4px -7px 8px -7px rgba(0,0,0,0.20);
    box-shadow: 4px -7px 8px -7px rgba(0,0,0,0.20);

    border-top: 1px solid var(--ion-color-light-shade); 
    border-left: 1px solid var(--ion-color-light-shade); 
    border-right: 1px solid var(--ion-color-light-shade); 
    border-right: none;
    border-bottom: none ;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; 
    z-index: 99; 
}
.home-screen-tabs ul li[aria-selected="true"] ion-icon{
    color: var(--ion-color-primary) !important;
}
.signin-screen-tabs ul.react-tabs__tab-list{
    border: none !important;
}
.home-screen-tabs.stick ul ,.signin-screen-tabs.stick ul { 
    position: absolute; left: 30vw; z-index: 9999;
    background-color: var(--ion-color-light);
    border-radius: 0px;
    border-bottom-left-radius: .7rem;
    border-bottom-right-radius: .7rem;
}  
.home-screen-tabs.stick ul li  { 
    border-radius: 0px;
    border-bottom-left-radius: .7rem;
    border-bottom-right-radius: .7rem;
}
.home-screen-tabs.stick ul li[aria-selected="false"]{
    color: var(--ion-color-dark);
}
.home-screen-tabs.stick ul li[aria-selected="true"]{
    -webkit-box-shadow: 4px -7px 8px -7px rgba(0,0,0,0.20);
    -moz-box-shadow: 4px -7px 8px -7px rgba(0,0,0,0.20);
    box-shadow: 4px -7px 8px -7px rgba(0,0,0,0.20);

    border-top: 1px solid var(--ion-color-light-shade); 
    border-left: 1px solid var(--ion-color-light-shade); 
    border-right: 1px solid var(--ion-color-light-shade); 
    border-right: none;
    border-bottom: none ;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; 
    z-index: 99; 
    color: var(--ion-color-light);
    background-color: var(--ion-color-primary);
 }


.dark .home-screen-tabs ul li[aria-selected="true"] ,
.dark .mdvr-screen-tabs ul li[aria-selected="true"]{
    background-color: var(--ion-color-primary);
}
.dark .home-screen-tabs ul li[aria-selected="false"] ,
.dark .mdvr-screen-tabs ul li[aria-selected="false"]{
    background-color: var(--ion-color-light-tint);
}

.home-screen-tabs ul , .mdvr-screen-tabs ul{ 
    border: none ;
    border-bottom: 1px solid  var(--ion-color-light-shade); 
 }


 .home-screen-tabs ul {
    background: var(--ion-color-light-shade) !important;
}
.home-screen-tabs ul li[aria-selected="true"] {
    box-shadow: none !important;
    border-radius: 0px!important;
    z-index: 99; 
    background:  var(--ion-color-light) !important;
    /* border-top: 1px solid #DDD ; */
    border-right: 1px solid #DDD ;
}

.home-screen-tabs ul li[aria-selected="false"] {  
    margin:0px;
    box-shadow: none !important;
    border-radius: 0px!important;
    background:  var(--ion-color-light-shade) !important;
    background-color: var(--ion-color-light-shade) !important;
    border-right: 1px solid #EEE ;
} 
 
 .devices-accordion-group ion-accordion ion-item ion-label{ font-size: .9em !important;}
 .devices-accordion-group ion-accordion ion-item  ion-icon{
    font-size: 1em !important;
 }
 .devices-accordion-group ion-accordion div[slot="content"]{
    margin-top: -1.5rem;
    height: fit-content !important;
 }

 





 /*---------*/
 .device-summary-popup .grid-table .icon{
    font-size: 1em;
    margin-right: .5rem;
    color: var(--ion-color-dark-tint);
}
.device-summary-popup .grid-table .text-content{ 
    color: var(--ion-color-dark-tint);
    margin-right: .5rem;
}
.device-summary-popup .grid-table .text-bold-content{
    font-size: .8em;
    color: var(--ion-color-dark-tint);
}
.device-summary-popup .grid-table  .flex-content{
    display: flex;
    flex-direction: row;
    align-items: center;
}


.device-summary-popup .grid-table  .bg-white-shade{
    padding: .3rem;
    background-color: rgba(var(--ion-color-light-shade-rgb), .5);
}
.device-summary-popup .grid-table  .bg-white-tint{
    padding: .3rem;
    background-color: rgba(var(--ion-color-light-shade-rgb), .1);
}

.chip-offline {
    --background: var(--ion-color-dark-tint);
    --color:  var(--ion-color-light);
}
.chip-overspeed { 
    --background: var(--ion-color-success);
    --color:  var(--ion-color-light);
    border: 1px solid var(--ion-color-danger);
}
.chip-move { 
    --background: var(--ion-color-success);
    --color:  var(--ion-color-light);
}
.chip-idle {
    --background: var(--ion-color-warning);
    --color:  var(--ion-color-light);
 }
.chip-stop  {
    --background: var(--ion-color-danger);
    --color:  var(--ion-color-light);
 }

 .splitpane.pane{
    background-color: var(--ion-color-light);
 }