.contents-light{
    background-color: var(--ion-color-light-tint) !important;
}
.group-report{
    border-radius: var(--ion-radius);
    padding: 0px;
    padding-bottom: .5rem;
}
ion-accordion ion-item.report-group-header{
    font-size: 1em; 
}
ion-accordion ion-item.report-group-header ion-icon{
    font-size: 1em;
    color: var(--ion-color-dark);
}

/* ion-list.group-report ion-item{
    color: var(--ion-color-medium-shade);
    font-size: 1em;
    --border-color:   #FFF;
}  */
.report-list{
    border-bottom: 1px solid var(--ion-color-light) ;
    /* --border-color: var(--ion-color-light) !important; */
    color: var(--ion-color-medium-shade);
    font-size: 1em;
    cursor: pointer;
    background-color: var(--ion-color-light-shade);
    transition-duration: 300ms;
}
.report-list:hover{
    background-color: var(--ion-color-light-tint) !important;
}
.report-list[aria-label="last"]{
    border: none;
    border-bottom-left-radius: var(--ion-radius);
    border-bottom-right-radius: var(--ion-radius);
}

table.react-table { width: 100%;  }
tr.header th{ padding: 1rem;padding-bottom: 1.5rem; color: var(--ion-color-medium-shade); font-weight: 400;font-size: .8em;}
tr.header th[aria-label="first"]{
    border-top-left-radius: var(--ion-radius);
    /* background-color: var(--ion-color-light) ; */
}
tr.header th[aria-label="last"]{
    border-top-right-radius: var(--ion-radius);
    /* background-color: var(--ion-color-light) ; */
}
tr.header th[aria-label="between"]{
    /* background-color: var(--ion-color-light) ; */
}
tr.header th {
    /* background-color: rgba( var(--ion-color-medium-rgb), .06); */
    /* border-bottom: 1px solid  rgba( var(--ion-color-medium-rgb), .2); */
}



tr.row td.td[aria-label="corner-top-left"]{
    border-top-left-radius: var(--ion-radius); 
}
tr.row td.td[aria-label="corner-top-right"]{ 
    border-top-right-radius: var(--ion-radius);  
}
tr.row td.td[aria-label="corner-bottom-left"]{
    border-bottom-left-radius: var(--ion-radius); 
}
tr.row td.td[aria-label="corner-bottomright"]{
    border-bottom-right-radius: var(--ion-radius); 
}



.table-container{
    border-radius: var(--ion-radius);
    /* border: 1px solid #DDD; */
} 
tbody.table-body { border-radius: var(--ion-radius);  }
tbody.table-body  tr.row td.td{
    padding: .7rem; 
}

tbody.table-body  tr.row[aria-label="odd-row"]{ 
    /* background-color: rgba( var(--ion-color-medium-rgb), .04); */
    background-color: var(--ion-color-light);
    border-bottom: 1px solid  rgba( var(--ion-color-medium-rgb), .07);
    transition-duration: 400ms;
}
tbody.table-body  tr.row[aria-label="even-row"]{ 
    background-color: var(--ion-color-light);
    border-bottom: 1px solid  rgba( var(--ion-color-medium-rgb), .07);
    transition-duration: 400ms;
}
tbody.table-body  tr.row[aria-label="odd-row"]:hover , 
tbody.table-body  tr.row[aria-label="even-row"]:hover{
    background-color: var(--ion-color-light-shade);
}
th {
    /* border-bottom: 1px solid lightgray;  */
    padding: 2px 4px;
} 
tfoot {
    color: gray;
}
tfoot th {
    font-weight: normal;
}
.control-table{ 
    /* background-color: rgba( var(--ion-color-medium-rgb), .1); */
    padding: .5rem;
    border-radius: var(--ion-radius);
    margin-bottom: 1rem;
}
.control-table ion-searchbar{
    padding: 0;
    height: 2.5rem;
    font-size: .7em !important;
    border: 1px solid rgba( var(--ion-color-medium-rgb), .1);
    border-radius: var(--ion-radius);
    background-color: var(--ion-color-light);
    max-width: 20rem;
}
.control-table .filter-button{
    height: 2.5rem;
    padding: 0;
    margin: 0;
    border-radius: var(--ion-radius);
    border: 1px solid rgba( var(--ion-color-medium-rgb), .1); 
}
.control-table .filter-button ion-label{
    font-size: .8em;
}
.toolbar-report{
    --background: var(--ion-color-light);
    border-radius : 5px;
    border: 1px solid #CCC;
    width: 100%; 
}

ul.toolbar  {
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: flex-start;
    margin: 0px !important;
    margin-right:2rem;
    border-bottom: 1px solid #DDD;
}

ul.toolbar li{ 
    width:  fit-content;
    list-style: none;
    padding: .3rem; 
}

/* @media only screen and (max-width: 500px) {
    ul.toolbar  {
        display:flex;
        flex-direction:column !important;
        align-items: center;
        justify-content: flex-start;
        margin: 0px !important;
        margin-right:2rem;
        border-bottom: 1px solid #DDD;
    } 
    ul.toolbar li{ 
        width:  fit-content;
        list-style: none;
        padding: .3rem; 
    }
} */

 ion-select.select-vehicle{
    --background: var(--ion-color-light) ;
    --padding-start: .5rem !important;
    --padding-end: .5rem !important;
    --border-radius: 10px !important;
    font-size: .9em !important;
 }
 ion-select.select-vehicle  svg.ion-icon{
    font-size: .5em !important;
 }
 .date-rang-report{  
    border-radius: 10px;
    margin-right: .5rem !important; 
 }
 .date-rang-report, .reports-selection{
    width: 100% !important;
    display: flex;
    flex-direction: row;
 }
 .date-rang-report .date-start, .reports-selection, .export-excel{
    font-size: .9em;
    width: fit-content;
    padding: .5rem;
    border-radius: 5px;
    margin-right: .3rem;
 }
 .date-rang-report .date-start{
    display: flex ;
    flex-direction: row ;
    justify-content: space-between ;
 }
 .reports-selection{ background-color: var(--ion-color-light-shade); }
 @media screen and (max-width: 600px) {
    .reports-selection{
     width: 100%;
     padding: .3rem;
    }
 }

 .date-rang-report{  
    border-radius: 10px;
 }
 .date-rang-report.show-bg{ 
    background-color: var(--ion-color-light-shade);
    border-radius: 5px; 
 }
 .date-rang-report .date-start ion-icon{
    font-size: .7em;
 }
 .date-start{
    display:flex;
    justify-content:space-between;
    align-items: center;
 }
 ul.toolbar.bottom{ 
    background-color: var(--ion-color-light);
    border-radius :0px !important;
    border: none;
    padding: .3rem;
}
ul.toolbar.bottom.end{
    padding-right: 3rem;
}
.export-excel{ 
    font-size: .9em;
    width: fit-content;
    padding: .3rem .5rem .3rem .5rem;
    border-radius: 8px;
    /* background-color: var(--ion-color-light); */
    margin-right: .3rem; 
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; 
    /* box-shadow: -2px 5px 13px -3px rgba(0,0,0,0.27); */
}
.export-excel ion-label{
    font-size: .8em;
    margin-right: .5rem !important;
}
.export-excel img{
    height: 1.5em;
}

.date-rang-report{

    --width: 40rem;
}
li.separator{
    font-size: .9em;
    color: var(--ion-color-medium);
}
.report-content-container{
    background-color: var(--ion-color-light);
    /* border: 1px solid #DDD; */
    /* margin-top: .5rem; */
    /* height: 80vh !important; */
    /* border-radius: 5px; */
}
.button-search{
    background-color: var(--ion-color-primary);
    padding: .3rem .5rem .3rem .5rem;
    color: #FFF;
    border-radius: 5px;
    width: fit-content; 
    font-size: .9em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition-duration: 300ms;
}
.button-search ion-icon{
    margin-right: .5rem;
}
.button-search:hover{
    background-color: rgba( var(--ion-color-primary-rgb), .8)
}
.monthly-report-selection{
    --width: 23rem;
} 

iframe.preview-report{
    width:100%; 
    border:none;
    background:#FFF !important;
  }

.paper-report{ 
    background-color: var(--ion-color-light);
    align-self: center; 
    padding: 1rem;
    /* border: 1px solid #EEE;  */
    margin: .5rem;
    width: 100vw !important; 
}
.paper-report.landscape{
    width: 90vw !important; ;
}
.paper-report.portrait{
    width: 60vw !important; ;
}

.paper-report .header{
    background-color: #EEE;
}

.table-container.table-report  {
    background-color: #FFF !important;
}
.table-report  .row-header{
    border: none;
}
.row-header .headers{
    background-color: #EEE;
    height: 3rem !important;
    padding: .3rem; 
}

.row-header  .rows.odd{
    /* background-color: #EEE; */
}

.row-header  .rows{
    padding: .5rem;
} 

.input-fuel{
    border:1px solid #DDD ;
    border-radius:5px;
    height:2rem;
    margin-right:.5rem;

    --color: var(--ion-color-dark);
}