
#map-route{ height: 100% !important; }
#map-route-add{ height:  80vh !important; }
.table-travelroute-container{
    width: 100%;
}

.table-travelroute-container .top-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem ;
    background: var(--ion-color-light-tint)  ;
    border-bottom: 1px solid #DDD;
} 
.table-travelroute-container table .row-header{
    background-color: var(--ion-color-light-tint);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; 
}
.table-travelroute-container table{  font-size: .9em; }
.row-header th ,.row-data td{
    padding: .5rem;
    
}
.row-header th { 
    padding-top: 1rem;
    font-weight: 400 !important;
}
.row-data td{
    /* font-size: .9em; */
}

.addroute-searh-station{ 
    background-color: var(--ion-color-light-tint);
    padding: 1rem;
}
.addroute-searh-station th.cell-heading ion-label{
    font-size: small;
    font-weight: 600 !important;
}
.addroute-searh-station th.cells-data ion-label{
    font-size: small; 
}

.station-modal{
    --width: 95vw;
    --height: 95vh;
}
@media only screen and (max-width: 760px) { 
    .station-modal{
        --width: 100vw;
        --height: 100vh;
    }
    #map-route{ height:  50vh !important; }
} 


.leaflet-tooltip.route-road{
    font-family:  'Inter','Prompt', sans-serif !important;
    border: none;
    background-color: rgba(var(--ion-color-dark-rgb) ,0);
    box-shadow: none; 
    font-weight: 600;
    bottom: -2.7rem;
    left: .25rem;
    padding: 3px;   
    background-color: var(--ion-color-light);
    border-radius: 10px; 
    box-shadow: -2px 7px 35px -12px rgba(0,0,0,0.75);
}
.table-bottom-station{
    background-color: var(--ion-color-light);
    width: 100%;
    padding: .5rem;
    border: 1px solid #DDD;
}

.btn-cell-datetime{
    padding: 0px !important;
    /* background-color: var(--ion-color-light); */
}
.table-station-selected{
    background: var(--ion-color-light-tint);
    min-height: 20vh  ;
    max-height: 70vh ;
    overflow-y: scroll ;
}
.zone-selection{ cursor: pointer;}
.zone-selection:hover{
    --background-color: var(--ion-color-medium) !important;
    --color: rgba(var(--ion-color-primary-rgb) , 0.8 );
}
.zone-selection.selected{
    --background-color: var(--ion-color-light-medium);
}