
ion-popover.filter{
    --width	: 26rem !important; 
    --backdrop-opacity:.1;
    --box-shadow: none !important; 
    --margin-left: 1.5rem !important;
    --offset-x: 1.5rem;
    --offset-y: -1.4rem; 
    --height: 75vh; 
}  

.filter-content{   
    background-color: var(--ion-color-light); 
    border-radius: 2rem !important; 
}
.filter-content .title{
    font-weight: bold;
}

.display-types{
  font-size: small;
}
.display-types ion-checkbox {
    --size: 18px; 

}
.display-types ion-checkbox::part(container) {
    border-radius: 6px;
}

ion-radio::part(container) {
  width: 20px;
  height: 20px;

  border-radius: 5px;
  border: 2px solid #ddd;
}

ion-radio::part(mark) {
  background: none;
  transition: none;
  transform: none;
  border-radius: 0;
}

ion-radio.radio-checked::part(container) {
  background:var(--ion-color-primary);
  border-color: transparent;
}

ion-radio.radio-checked::part(mark) {
  width: 6px;
  height: 10px;

  border-width: 0px 2px 2px 0px;
  border-style: solid;
  border-color: #fff;

  transform: rotate(45deg);
}


  .radio-checked.ios {
    border-color: var(--ion-color-primary); 
  }