 .contents{
    background-color:  var(--ion-color-light-tint) !important; 
    height: 95vh;
}
.set-route-container{
    background-color: var(--ion-color-light);
    height: 100%;
    border-radius: 15px; 
    box-shadow: -5px 2px 22px -1px rgba(0, 0, 0, 0.04);
    /* padding: 1rem;
    padding-top: 1.5rem; */
    border: 1px solid #e4e4e4;
}
.set-route-container ion-header{
    padding: 1rem;
    /* padding-top: 1.5rem; */
}
.set-route-container .content{
    padding: 1rem;
    padding-top: 0px;
}
.routes-list{
    border-bottom: 1px solid #EEE;
}
.icon-route-name{
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    background-color: antiquewhite;
    font-size:x-large;
    font-weight: bold; 
}
.routes-list:hover{
    background-color: var(--ion-color-light-tint);
    cursor: pointer;
}

.add-route-content{
    padding: .5rem;
    padding-top: 0px;
}
.add-route-content .input{
    margin-top: .5rem;
}
.input-unit {
    position:absolute;
    top:0px;
    right:20px;
    z-index:99 ; 
    height:100%;
    width:3rem;
}
.input-unit.small{ 
    width: 2rem;
    position:relative;
    background-color: var(--ion-color-light-tint);
}