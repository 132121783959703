.table{
    font-size: .9em;
    width: 100%;
    height: 70vh;
    overflow: scroll;
    max-width: 90vw;
}
.table table{
    width: 100%;
}

.table::-webkit-scrollbar {
    width: 5px !important;
  }
  
  /* Track */
  .table::-webkit-scrollbar-track {
    background: #f1f1f1  !important;
  }
  
  /* Handle */
  .table::-webkit-scrollbar-thumb {
    background: #888  !important;
  }
  