ion-card.card {
    box-shadow: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid var(--ion-color-light-shade);
    z-index: 9999 !important;
}
.card ion-card-content{
    font-size:1em; 
}
.card ion-card-title{
    font-size: 1.2em;
}
.sts-icon{
    position: absolute;
    width: 1rem;
    top: 5%;
    left: 30%;
}
.card .status-name{
    font-size: .9em;
    font-weight: bold;
}
.card .status-name{ color: var(---ion-color-light); }
.device-status-name.offline{ background-color: var(---ion-color-medium) ; }
.device-status-name.stop{ background-color: var(---ion-color-danger) ; }
.device-status-name.move{ background-color: var(---ion-color-success) ; }
.device-status-name.idle{ background-color: var(---ion-color-warning) ; }

ion-card-content{
    padding-bottom: 5px !important;
}
ion-card-header{ 
    padding-top: 7px !important;
}
 
ion-chip.chip{
    background-color: var(--ion-color-light);
    --boder-color: #EEE !important;  
    font-size: smaller !important;
}
ion-chip.chip.stack{  
    margin-left: -.7rem;
    margin-top: -.3rem;
    padding: .2em;
    min-width: 3rem;
    padding-left: .7rem;
    padding-right: .5rem;
    font-size: .8em;
    border: 1px solid var(--ion-color-light-shade);
} 
ion-chip.chip.stack ion-icon {
    font-size: medium;
}


ion-chip.chip.float{
    position: absolute;
    z-index: 999;
    right: .5rem;
    bottom: .2rem;    
    transition-duration: all ease 500ms !important;
}
ion-chip.chip.float:hover{
    min-width: 7rem; 
    max-width: fit-content;
    transition-duration: all ease 500ms !important;
}
ion-chip.chip.float .expire-date{
    display: none;
    transition-delay: 600ms;
}

ion-chip.chip.float:hover >.expire-date{
    display: block; 
}
ion-chip.chip.float:hover > ion-label[aria-label="expired"]{
    color: var(--ion-color-danger);
}
ion-chip.chip.float:hover > ion-label[aria-label="actived"]{
    color: var(--ion-color-success);
}

.icon-opt{
    color: rgba(var(--ion-color-secondary-rgb),.5); 
    cursor: pointer; 
    font-size: medium;
} 
.icon-opt:hover{
    color: var(--ion-color-primary) !important;
}

ion-popover.option-device{
    --width	: 26rem !important; 
    --backdrop-opacity:.1;
    --box-shadow: none !important; 
    --margin-left: 1.5rem !important;
    /* --offset-x: 1.5rem;
    --offset-y: -1.4rem;  */
} 
 

.badge-device-update{
    position: absolute;
    margin-left: .3rem;
    font-size: .6em;
    padding: 5px;
}
 
@media only screen and (min-width: 720px) {
    #option-modal{
        --width: 40vw !important;
        --max-width: 40rem;
        --height: 80vh;
        --border-radius: var(--ion-radius);
    } 
} 


.set-row-start{ 
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
#option-modal div.wrapper{
    padding: 1rem !important;
}
div.wrapper .option-content{
    border-radius: 5px;
    margin-top: 1rem;
    width: 100%;
    height: 78% !important;
}

div.wrapper  ion-card-title.title{
    font-weight: 900 !important;
    font-size: 1.5em;
}
div.wrapper  ion-card-subtitle.sub-title{ 
    font-size: 1em; 
}

div.message-content{
    padding: 1rem; 
}
div.messages-list{
    padding: 1rem;
    border-radius: 5px;
    height: 55vh;
    background-color: var(--ion-color-light-shade);
    overflow-y: scroll;
}


div.cdm-content{
    padding: 1rem;
    background-color: var(---ion-color-light);
}

div.cdm-content table.table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    background-color: var(---ion-color-light-shade);
    padding: .51rem;
  }
  
div.cdm-content table.table td, th {
    /* border: 1px solid  var(--ion-color-light-tint); */
    text-align: left;
    padding: 8px;
  }
  
div.cdm-content table.table tr:nth-child(even) {
    background-color:  var(--ion-color-light-tint);
  }

ion-popover.popover-share{
    --backdrop-opacity: 0.0.1 !important; 
    --width	: 26rem;
    --min-height: 10rem;
    -webkit-box-shadow: -1px 0px 39px -11px rgba(0,0,0,1);
    -moz-box-shadow: -1px 0px 39px -11px rgba(0,0,0,1);
    box-shadow: -1px 0px 39px -11px rgba(0,0,0,1) !important;
} 
ion-popover.popover-share .input ion-item ion-input input{ 
    font-size: .8em !important;
} 
ion-popover.popover-share .input ion-item ion-button{
    right: .2rem;
}
ion-popover.popover-share  .socail{
    font-size: 2em;
    margin-right: 1rem;
    
}
.close-share-popover{
    position: absolute;
    top: .5rem;
    right: .5rem;
    cursor: pointer;
}
 div.popover-arrow::after{ 
}

ion-radio.cartype-checkbox{
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 1rem;
    right: .3rem !important;
} 
.petroleum-choice ion-radio, .default-map-choice ion-radio{
    width: 20px;
    height: 20px;
}
ion-radio.cartype-checkbox.radio-icon{
    background-color: var(---ion-color-light) !important; 
}
.default-map-choice ion-radio::part(container){
    border-radius: 5px;
    border: 2px solid  var(--ion-color-primary);
}
ion-radio.cartype-checkbox::part(container) ,.petroleum-choice ion-radio::part(container){
    border-radius: 5px;
    border: 2px solid #ddd;
    width: 20px;
    height: 20px;
    background-color: var(--ion-color-light);
} 
ion-radio.cartype-checkbox::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;
}
  
  ion-radio.cartype-checkbox.radio-checked::part(container){
    background: var(--ion-color-primary);
    border-color: transparent;
  }
  .petroleum-choice ion-radio.radio-checked::part(container){ 
    border-color:  var(--ion-color-primary); 
    background-color: var(--ion-color-light);
  }
 
  ion-radio.cartype-checkbox.radio-checked::part(mark) {
    width: 6px;
    height: 10px;
  
    border-width: 0px 2px 2px 0px;
    border-style: solid;
    border-color: #fff;
    background-color: var(---ion-color-light) !important;
  
    transform: rotate(45deg);
  }


  .cmd-table{
    height: 25vh;
    border-radius: 20px !important;
    overflow-y: scroll;
  }
  .cmd-table .cmd-column{
    font-size: .73em;
    font-weight: bold;
    border-bottom: 2px solid #DDD;
    margin-bottom: .5rem;
  }
  .cmd-table .cmd-row{
    font-size: .7em;
  }
  .cmd-table .cmd-row.white{
    background-color: var(--ion-color-light);
  } 
  .cmd-table .cmd-row.tint{
    background-color: var(--ion-color-light-tint);
  } 
   
.input ion-item{
    --background: transparent !important;
}
.employee-name{ 
    bottom: 0px ; 
    z-index:9999; 
}
ion-img[alt="icon-type"]{
    max-width: 2.7rem ;
    border: 2px solid #ddd  ;
    border-radius: 50% ;
    max-width: 3rem;
    max-height: 3rem;
}

.engine-on{ filter: invert(51%) sepia(90%) saturate(647%) hue-rotate(79deg) brightness(106%) contrast(90%); }
.engine-off{filter: invert(12%) sepia(62%) saturate(6993%) hue-rotate(347deg) brightness(101%) contrast(96%);}

.pulse-icon{
    position: absolute ;
    left: -.7rem ;
    top:0rem ;
    font-size: .8em ;
    animation: pulse 2s infinite; 
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

input[type="file"] {
    display: none;
}
.circle-file-upload{
    border: 1px solid #ebe8e8;
    display: inline-block; 
    cursor: pointer;   
    display: flex;
    justify-content: center;
    align-items: center; 
    font-size: small;
    padding: 3px;
    width: fit-content;
    margin: .5rem  .3rem;
    border-radius: 5px;
}
.circle-file-upload:hover{
    background-color: #ebe8e8;
}

/* 1 acl.object_id as device_id ,
2 d.device_enabled , 
3 d.name , 
4 d.odometer_offset  , 
5 d.phone_number , 
6 d. account_id , 
7 d.device_type_id ,  
8 d.icon_id , 
9 d.expiration_date , 
10 d.owner_id , 
11 d.name2 , 
12 d.deviceIconId , 
13ec.fuel_liters,
14 ec.heading,
15 ec.latitude,
16 ec.longitude ,  
17 d.odoCheckToday  ,    
18 d.odoSummary , 
19 d.odoToday , 
20 ec.fld_driverID , 
21 ec.fld_driverMessage , 
22 ec.fld_engineLoad , 
23 ec.fld_sensorHigh , 
24 ec.fld_signalStrength ,
25 ec.satellites ,
26  ec.speed , 
27 ec.status , 
28 ec.status_time  , 
29 ec.event_stamp , 
30 ec.address ,
31 ec.id , 
32 ec.serverTime, 
33 ec.temperature , 
34 d.canCutEngin, 
35 ec.virtual_odometer , 
36 dt.name as device_type , 
37 '' as fullname    
38 d.ConsumptionIdle ,
39 d.fuelConsumptionMove,
40 d.pttType , '' as ptt , 
41 ec.ignition as ignition, 
42 go.group_id as group_id , 
43 gd.name  as group_name , 
44 d.minuteIdle  ,
45 d.speed_limit , \n" 
	46 go.group_id as groupId  */