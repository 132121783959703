.drivingpoint-form{
    padding: 1.5rem;
    width: 100%;
    background-color: var(--ion-color-light);
    min-height: 80vh;
    border-radius: 15px;
}
.backgroung-tint{
    background-color: var(--ion-color-light-tint);
}

.segment-event-point{
  width: 100%;
}

.segment-event-point ion-segment-button{
    font-size: .7em !important;
  }
  .segment-event-point ion-segment-button.segment-button-checked{
    font-weight: bold !important;
  }
  
  .segment-event-point ion-segment-button.segment-button-checked .point{
    color: var(--ion-color-danger);
    font-size: larger;
  }
  .swiper-event-point .events-container{
    width: 100%;
    height: 65vh;
    background-color: var(--ion-color-light-tint);
    overflow-y: scroll;
    border-radius: 10px;
  }
  .each-event{
    width:95%;
    margin-left: 2.5%;
    margin-top: .5rem;
    border-radius: 5px;
    background-color: var(--ion-color-light);
    padding: .3rem;
    position:relative;
    font-size: .8em;
  }
  .each-event .close{
    position: absolute;
    right: .5rem;
    top: .5rem;
   z-index: 999;
  }
  