#webpack-dev-server-client-overlay , #webpack-dev-server-client-overlay-div{
  z-index: -99;
  display: none !important;
}
.Longdo {
  height: 100vh !important;
  width: 100% !important;;
}

#longdo-map, #route-longdo-map{
  border-radius: .5rem;  
  height: 100% !important;
  /* height: 100vh !important;
  width: 100% !important;; */
}

/* #result { 
  top: 0;
  bottom: 0;
  right: 0;
  width: 1px;  
  margin: auto;
  border: 4px solid #dddddd;
  background: #ffffff;
  overflow: auto;
  z-index: 2;
  width: 100%; 
  height: 75vh;
  display: none;
}
#result  ol{ 
}
#result  div.ldroute-info{
  height: 3rem !important;
} */