

.title{
    padding-left: 0px;
    margin: 0px;
}
.title sub{
    font-size: .5em !important;
    font-weight: 200 !important; 
    line-height: .4rem !important;
}

.start-center{
    text-align: left !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
ion-grid.table{ 
    left: 1rem;
    margin-top: .5rem;
}
ion-row.row-header{
    font-size: .7em;
    font-weight: bold;
    color: var(--ion-color-dark-tint);
    border-top: 1px solid var(--ion-color-light-shade);
    border-bottom: 1px solid var(--ion-color-light-shade);
    margin-bottom: .5rem;
}
ion-row.row-header ion-col{
    padding: .5rem;
    text-align: center;
}

ion-row.row-body{
    color: var(--ion-color-dark);
    font-size: .75em;
    padding-top: .2rem;
    padding-bottom: .2rem;
}
ion-row.row-body ion-col{
    text-align: center;
}
.row-body ion-col#email{
    font-size: .7em;
}

.btn-edit,.btn-active{
    background-color: rgba(var(--ion-color-secondary-rgb) , .1);
    min-width: 4rem;
    max-width: 5.5rem;
    padding: .3em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    color: var(--ion-color-secondary);
    cursor: pointer;
}
.btn-edit ion-icon ,.btn-active.true ion-icon,.btn-active.false ion-icon{
    margin-right: .2rem;
    font-size: 1.4em !important;
}
.btn-edit ion-label ,.btn-active  ion-label{ 
    font-size: .9em;
}
.btn-edit:hover{
    color: rgba(var(--ion-color-secondary-rgb) , .5);
}
.btn-active.true{
    background-color: rgba(var(--ion-color-success-rgb) , .1);
    color: var(--ion-color-success);
}
.btn-active.false{
    background-color: rgba(var(--ion-color-danger-rgb) , .1);
    color: var(--ion-color-danger);
}


.admin-profile{ 
    padding: 1rem;
    background-color: var(--ion-color-light-tint);
    margin: 1rem;
    border-radius: 15px;
}
.admin-profile .input{
    background-color: var(--ion-color-light-shade); 
    padding-left: .5rem;
    height: 2.5rem !important;
}
.admin-profile .input ion-input{
    height: 100% !important;
    font-size: .8em !important;
}
.admin-profile .input ion-select{
    font-size: .8em !important;
    width: 100%;
}
.admin-profile .input ion-select svg.icon{ 
    float: right;
}
.admin-profile .input ion-select .native-wrapper{
    width: 100% !important;
}

.popover-select {
    --width: 15rem;
    --min-height: 25vh;
    --max-height: 50vh;
}
.popover-select ion-radio-group ion-radio{
    --checkmark-color: var(--ion-color-primary);
}

.add-edit-table-modal{
    --width: 50vw;
    --height: 90vh;
}


.userprofile-title{ 
    padding: .5rem !important;
    font-weight: bold; 
}
.user-add-edit-container{
    font-size: .85em !important;   
}

.user-add-edit-container .input{
    font-size: .9em !important;  
}

.device-to-view-container{
    background-color: var(--ion-color-light-tint);
    width: 100%;
    min-height: 5rem;
    border-radius: 10px;
    height: 35vh;
    overflow-y: scroll;
}

.device-to-view-container ion-item{
    border-bottom: 1px solid var(--ion-color-light-shade);
    font-size: .85em !important;   
}
.device-restrictions{
    margin-bottom: 1rem;
}
.device-restrictions .restrictions-title{
    font-size: 12px; 
    font-weight: bold;
    line-height: normal;
}

.device-restrictions  .restrictions-checked{
    color: var(--ion-color-medium); 
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.device-restrictions ion-item{
    border-bottom: 1px solid var(--ion-color-light-shade); 
    margin-bottom: .5rem;
}


.add-edit-device-container .input{
    font-size: .8em;
}

.segment-device-sensor{
    width:15rem;
    margin-left:1rem;
    position:absolute;
    z-index:3;
    top:-1.5rem;
    --background: var(--ion-color-light-tint) !important;
    border-bottom: 1px solid #EEE;
}


.add-digital-sensor{
    --width: 25rem !important;
    --height: 40vh;
}
.add-analog-sensor{
    --width: 35rem !important;
    --min-height: 40vh;
}
.admin-search-table{
    --background: var(--ion-color-light) !important;
}
.admin-search-table .searchbar-input{ 
    border: 1px solid #EEE;
}
ion-row.grid-rows.headers{
    background-color:var(--ion-color-light-tint) !important;
    border-radius:10px;
}
ion-row.grid-rows.headers .title{
    font-size: 1.3rem;
    font-weight: bold;
}
ion-row.grid-rows{
    border-radius:10px;
    margin-top:.5rem;
    font-size: .85rem; 
}
ion-row.grid-rows.shade{ 
    background: var(--ion-color-light-tint) !important;
}
ion-row.grid-rows.light{
    background: var(--ion-color-light) !important;
}
ion-row.grid-rows .table-admin-btn-edit{
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 7px;
    background-color: transparent;
    border: 1.5px solid var(--ion-color-dark);
    color: var(--ion-color-dark);
}