.maintenance-table{
    width: 100% !important; 
    font-size: small;
    min-width: 50rem;
    overflow-x: scroll;
}

.table-header-grid{
    background-color: var(--ion-color-light) !important;
    width: 100%;
    padding-left: .5rem;
    border-radius: 10px;
    font-weight: bold;
}
.table-rows-grid{ 
    background-color: var(--ion-color-light) !important;
    width: 100%;
    padding-left: .5rem;
    border-radius: 10px;
    margin-top: .5rem;
    font-size: smaller;
    min-height: 2.5rem;
}
.table-rows-grid ion-col{
    display: flex;
    align-items: center;
}

.add-maintenance-conatiner{
    background-color: var(--ion-color-light) !important;
    width: 100%;
    padding-left: .5rem;
    border-radius: 10px;
    margin-top: .5rem;
    
}

.maintenance-condition{
    display:flex ;
    align-items:center;
    font-size: small;
    margin-right: 1rem;
}

.add-maintenancetype-conatiner{
    background-color: var(--ion-color-light) !important; 
    padding:1rem;
    border-radius: 10px;
    margin-top: .5rem;
    max-width: 50rem; 
}

ion-toggle.ispublic-maintenancetype{ 
    --track-background: var(--ion-color-danger);
    --track-background-checked: var(--ion-color-success);
    color: #FFF !important;
}