
ion-range.range-passroute-status{ 
  --bar-background: transparent;
  --bar-background-active:transparent;
  --bar-height: 8px;
  --knob-size: 15px;
  --width: 100%;  
  position: absolute;
  top: -15px;
  width: 100%;
}
.wrap-range-status{
    width: 100%;
    height: 12px; 
    position: relative;
    margin-bottom: 2rem;
    border: 1px solid #FDFDFD;
}
.status-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: absolute;
    top:0px;
    background-color: #FDFDFD;
    border: 1px solid #EEE;
}
.status-inrange{ height: 100% !important; }
.status-inrange.bg-move{ background-color: var(--ion-color-success); }
.status-inrange.bg-idle{ background-color: var(--ion-color-warning); }
.status-inrange.bg-stop{ background-color: var(--ion-color-danger); }

ion-radio.radio-color{
  background-color: color ;
  margin-right:.3rem;
  border-radius: 7px;
 }
ion-radio.radio-color::part(container) {
  width: 20px;
  height: 20px; 
  border: 1px solid #FFF;
  border-radius: 7px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
}
ion-radio.radio-color::part(container) {
  background: transparent;
}
.passroute-container{
  width: 100% ;
  height: 97vh ;
  overflow-y: scroll ;
  background: var(--ion-color-light)  ;
  border: 1px solid #EEE ;
  border-radius: 10px ;
  position:  relative ,
}
.playback-control{
  position: absolute ;
  z-index:9999 ;
  bottom: 1rem ;
  border-radius: 5px ;
  background: var(--ion-color-light) ;
  min-width: 30rem  
}