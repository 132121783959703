 
.container-devices,.container-history{
    position: absolute;
    width: 25rem;
    top: 1rem;
    left: 1.5rem;
    z-index: 999; 
    height: fit-content !important;
    background-color: var(--ion-color-light);
    border-radius: 20px;
}
/* .container-devices{
    top: 1.5rem !important;
} */
.container-search{
    background-color: var(--ion-color-light); 
}
.container-devices .content{
    background-color: var(--ion-color-light);
    height: 80vh;
    width: 25rem  ;
    padding-left: -3rem;
    z-index: 999;
    padding: .5rem;
    border-radius: 15px; 
    padding-top: 0px;
    overflow-y: scroll;
}

.container-devices .content  ion-nav{
    left: -10.5%;
}

@media only screen and (max-width:1200px) {
    .container-devices .content{
        height: 65vh; 
        width: 22rem ;
    }
    .container-devices{ 
        width: 22rem  ; 
    }
  }
.preview-route-history::-webkit-scrollbar {
    width: 0px !important;
}
[part="scroll"]::-webkit-scrollbar {
    width: 0px !important;
}

.container-search{ 
    border-radius: 17px; 
}

.set-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.flex-row{
    flex-direction: row; 
}

.list-content{
    width: 100%;
    height: 100% ;
    overflow: scroll; 
    background-color: var(--ion-color-light);
    overflow-y:scroll;
    height: 85vh;
    padding-bottom: 5rem;
}
.list-content ion-segment  {
    --background: var(--ion-color-light) !important;
}
/* width */
.list-content::-webkit-scrollbar {
    width: 3px;
    border-radius: 15px;
} 
  /* Track */
.list-content::-webkit-scrollbar-track {
    background:  var(--ion-color-light) ;
    border-radius: 15px;
} 
/* Handle */
.list-content::-webkit-scrollbar-thumb {
    background: rgba( var(--ion-color-primary-rgb), .3) ;
}
.page-slider{ background-color: var(--ion-color-light);}
ion-segment.status-device{
    padding-left: .1rem; 
    background-color: var(--ion-color-light); 
    padding-bottom: .5rem; 
    max-width: 30rem;
}
ion-segment.status-device ion-segment-button{padding-top: .5rem; --background: var(--ion-color-light) }
ion-segment.status-device ion-segment-button ion-icon{
    font-size: 1.3em;
}
ion-segment.status-device ion-segment-button .status-size{
    font-size: 1em;
    display: none;
} 
ion-segment.status-device ion-segment-button:hover >.status-size {
    display: block;
    color: var(--ion-color-primary);
}
ion-segment.status-device ion-segment-button:hover >.status-name {
    display: none;
}
ion-segment.status-device ion-segment-button[value="move"]:hover >.status-size {
    color: var(--ion-color-success);
}
ion-segment.status-device ion-segment-button[value="idle"]:hover >.status-size {
    color: var(--ion-color-warning);
}
ion-segment.status-device ion-segment-button[value="stop"]:hover >.status-size {
    color: var(--ion-color-danger);
}
ion-segment.status-device ion-segment-button[value="offline"]:hover >.status-size {
    color: var(--ion-color-dark);
}
.status-device ion-segment-button{
    color:  rgba(var(--ion-color-medium-rgb), .7);
    font-size: .65em !important;
    
} 
ion-segment.status-device ion-segment-button.segment-button-checked{
    font-weight: bold;
}
ion-segment.status-device ion-segment-button[value="all"].segment-button-checked{
    color: var(--ion-color-primary);
}
ion-segment.status-device ion-segment-button[value="move"].segment-button-checked{
    color: var(--ion-color-success);
}
ion-segment.status-device ion-segment-button[value="idle"].segment-button-checked{
    color: var(--ion-color-warning);
}
ion-segment.status-device ion-segment-button[value="stop"].segment-button-checked{
    color: var(--ion-color-danger);
}
ion-segment.status-device ion-segment-button[value="offline"].segment-button-checked{
    color: var(--ion-color-medium);
}

#ion-react-wrapper{
    z-index: 998 !important;
}

#device-preview {
    font-size: medium; 

}
#device-preview .device-name{
    font-size: 1.15em;
    font-weight: bolder;
    color: var(--ion-color-dark);

}

#device-preview ion-icon,#device-preview ion-label.icon{
    font-size: 3em !important;
    /* color: rgba( var(--ion-color-secondary-rgb), .6); */
    color: #71B8F4;
}
#device-preview .status-name{ 
    color: var(--ion-color-medium);
}

#device-preview .status-value{ 
    font-size: 1em;
    font-weight: bold;
    color: var(--ion-color-dark-tint);
}

.preview-device-contianer{
 padding-top: 0;
 background-color: var(--ion-color-light);
}
.preview-device-contianer .device-name{ 
    font-size: 1.1em; 
    font-weight: bold;
}
small.event-time{ 
    color: var(--ion-color-medium); 
    font-size: .7em;
    font-weight: bold;
}
.preview-device-contianer .status-name{
    font-weight: bold;
    font-size: smaller;
}
.preview-device-contianer .grid-table .icon{
    font-size: 1em;
    margin-right: .5rem;
    color: var(--ion-color-dark-tint);
}
.preview-device-contianer .grid-table .text-content{ 
    color: var(--ion-color-dark-tint);
    margin-right: .5rem;
}
.preview-device-contianer .grid-table .text-bold-content{
    font-size: .8em;
    color: var(--ion-color-dark-tint);
}
.preview-device-contianer .grid-table  .flex-content{
    display: flex;
    flex-direction: row;
    align-items: center;
}


.preview-device-contianer .grid-table  .bg-white-shade{
    padding: .3rem;
    background-color: rgba(var(--ion-color-light-shade-rgb), .5);
}
.preview-device-contianer .grid-table  .bg-white-tint{
    padding: .3rem;
    background-color: rgba(var(--ion-color-light-shade-rgb), .1);
}

.segment-label{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.segment-label .icon{
    font-size: 1.4em;
    margin-right: .5rem;
}
.segment-label span{
    font-size: .9em !important; 
}


.summary-device,.action-device,.profile-device{
    width: 100%;
    background-color: var(--ion-color-light-tint-rgb) ;
    color: var(--ion-color-dark);
    padding: .5rem ;
    margin-top: .5rem ;
    font-size: .8em;
    border-radius: 10px;
    overflow-x: scroll;
    min-height: 10rem;
    /* max-height: 17rem; */
}
.summary-device ion-row ,.action-device ion-row , .profile-device ion-row {
    border-bottom: 1px solid #EEE;
    padding: .2rem;
}
.summary-device 
.summary-device .summary-title{
    color: #8F8F8F; 
}
 
.action-icon{
    font-size: 1.3em;
    color: var(--ion-color-medium);
}
.preview-route-segment {
    width: 90%;
    left: 5%;
}
.preview-route-segment ion-segment-button ion-label{
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    align-items: center !important;
}
.preview-route-segment ion-segment-button .icon{
    margin-right: .5rem;
    font-size: 1.3em;
}
.preview-route-segment ion-segment-button span{
    font-size: .8em;
    padding: 0;
    margin: 0;
}
span.overview-title{
    font-size: .6em !important;
}
.overview-val{
    font-weight: bold;
}

.route-list-preview-container{
    background-color:  var(--ion-color-light-tint-rgb) ;
    padding: .5rem;
    margin-top: 1rem;
    border-radius: 10px;
    height: 22rem;
    overflow-y: scroll;
}
.route-list-preview-container::-webkit-scrollbar {
    width: 0px; 
}
.route-list-preview {
    border-bottom: 1px dashed #EEEE;
    padding-bottom: .5rem;
}
.route-list-preview .route-day{
    font-size: .85em;
    font-weight: bold;
    color: var(--ion-color-dark);
}
.route-list-preview .route-date{
    font-size: .7em; 
    color: var(--ion-color-dark);
}
.route-list-preview .name{
    font-size: .5em; 
}
.route-list-preview .val{
    font-size: .8em;
    font-weight: bold;
    color: var(--ion-color-medium-shade); 
}

.line-btw-date{
     width: 100% ;
     height:1px;
     border:1px solid #FB7B04; 
     position: relative;
}
.line-btw-date .left ,.line-btw-date .right{
    position: absolute; 
    font-size: .5em;
    color: var(--ion-color-primary);
}
.line-btw-date .left{
    left: -.25rem;
    top: -.25rem;
}
.line-btw-date .right{
    right:  -.25rem;
    top: -.25rem;
}
.popup-dragale-content{
    --width: 30rem; 
}
.popup-dragale-content .close-popup{
    position:absolute;
    right:1rem;
    top:1rem;
    z-index: 999 !important;
}
.route-dragale-content .date{
    color: var(--ion-color-medium-shade) !important;
}

.route-dragale-content
.route-bar {
    width: 100%;
    background-color: #8F8F8F;
    display: flex;
    flex-direction: row;
    z-index:2;
}
.route-bar .status{
    float: left;
    height: .3rem;
}
.status-move{
    background-color: var(--ion-color-success);
    height: 2rem;
}
.status-stop{
    background-color: var(--ion-color-danger);
    height: 2rem;
}
.status-idle{
    background-color: var(--ion-color-warning);
    height: 2rem;
}
.status-offline{
    background-color: var(--ion-color-medium);
    height: 2rem;
}
ion-range.route-bar-range{
    width: 100%;
    position: absolute;
    z-index: 1;
/* 
  --bar-background: rgba(255,255,255,0) !important; 
  --bar-background-active: rgba(255,255,255,0) !important; 
  --pin-background:  rgba(255,255,255,0)  !important;  */


  --bar-background:  rgba(255,255,255,0) !important; 
  --bar-background-active:  rgba(255,255,255,0) !important; 
  --bar-height: 8px;
  --bar-border-radius: 8px;
  --knob-background:  rgba(255,255,255,1) !important; 
  --knob-size: 1rem;
    --pin-background:  rgba(255,255,255,1) !important; 
     --pin-color:  rgba(255,255,255,1) !important; 
}
 

.dowload-device-history-detail{
    --width: 7rem;
}
.popover-datetime-range{
    --width: 40rem; 
}
.popover-datetime-range::part(content){
   margin-left: 5rem;
}

.summary-val.input.consumption{
    font-size: .8em;
}

#list-colred-scroll{
    height: 72vh;
    overflow-y:scroll;
    background-color: #fff;
}
#list-colred-scroll::-webkit-scrollbar {
    width: 5px !important;
}
#list-colred-scroll::-webkit-scrollbar :hover {
    width: 7px !important;
}
#list-colred-scroll::-webkit-scrollbar-track {
    background: #d6d6d6;
    border-radius: 5px !important;
}
#list-colred-scroll::-webkit-scrollbar-thumb {
    background: #bdbdbd;
}


.ldevice{
 /* border-bottom: 1px solid #000; */
}
.content-hidden[area-hiden="fixed"]{
    font-size: .9em;
    display: block;
    box-shadow: -5px 24px 33px -22px rgba(0,0,0,0.71);

}
.content-hidden[area-hiden="auto"]{
    font-size: .9em;
    display: none;
    
}
.ldevice:hover > .content-hidden[area-hiden="auto"]{
    display: block;
    cursor: pointer;
}

.tooltip {
    position: relative;
    display: inline-block; 
    cursor: pointer;
    justify-content: center;
  }
   
  .tooltip .tooltiptext {
    visibility: hidden;
    min-width: 100px;
    background-color: rgba( var(--ion-color-dark-rgb), .8);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    margin-left: -3.5rem;
    margin-top: 1rem;
    position: absolute;
    padding: 5px 10px 5px 10px;
    z-index: 9999;
    font-size: small;      
    border: none !important;
  }
   
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }