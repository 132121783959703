.table{ 
    min-width:  30rem !important;  
}

.table ion-row.header{
    font-size: .7em;
    background-color: var(--ion-color-light-shade);
    border-bottom: 1px solid #efefef;
}

.cells{
    transition-duration: 300ms;
    width:100%;
    height:100%;
    font-size: small;
    background-color:#FDFDFD;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
}

#rep-travelsum-map{
    height: 20rem !important;
    max-width: 40rem;
}
#map-address{
    height: 25rem !important;
}

table.table-minute{
    font-size: .8em;
    border-radius: 10px !important;
}

table.table-minute .header {  
    background-color: #EEE;
    color: #000;
    border-radius: 30px !important;
}
table.table-minute tr th {
    color: #000 !important;
}
table.table-minute tr td {
    padding: 1rem  0 1rem .5rem;
}
.popover-device-select{
    --width: 15rem;
}
.table{
    /* background-color: #000; */
}
.table::-webkit-scrollbar {
    width: 10px !important;
    border-radius: 0px;
    background: #EEE;
}
.table::-webkit-scrollbar-track {
    background: red;
    width: 10px !important;
}

/* Handle */
.table::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  .table::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }

.event-row{
    background-color: var(--ion-color-light);
}
.odd-row{

    background-color: var(--ion-color-light-tint);
}
  