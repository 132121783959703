.report-container{ 
    width:14rem ;
    position:relative ;
    transition-duration: 300ms;
}
#expand-report-selection::before{
    content: '';
    padding-top: .5rem !important;
}
#expand-report-selection{
    width: 100% ;
    position:  absolute ; 
    z-index:99;
    display: none;
}
.report-container .swiper{ 
    margin-top: .5rem;
    background-color: var(--ion-color-light-shade) ; 
    border-radius: 5px ;
    border: 1px solid #EEE ;
    padding: .2rem ;
    height: fit-content !important;
}
.report-container:hover > #expand-report-selection{
    display: block;
} 