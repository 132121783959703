@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Kanit:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt&display=swap');
/* @import url('http://mdvr.attg.cc/js/skins/default.css'); */
 
#webpack-dev-server-client-overlay {
  display: none !important;
}

body,html,.app{
  font-family:  'Inter','IBM Plex Sans Thai', sans-serif !important; 
}
ion-label , ion-text, p , label { 
  font-family:  'Inter','IBM Plex Sans Thai', sans-serif !important;
  font-weight: 400 !important;
}
strong , b , ion-title{
  font-weight: 600 ;
}
ion-header.header{background-color: var(--ion-color-light);}
ion-header.header::after{
    box-shadow: none !important;
    background-image: none;
    /* border-bottom: 1px solid #f2f2f2; */
    /* opacity: 0.5; */
    margin: 0;
    padding: 0;
    background-color:var(--ion-color-light);
}

.cursor-pointer{cursor: pointer;}
.bg-light{
    background-color: var(--ion-color-light);
}
.bg-light-tint{
  background-color: var(--ion-color-light-tint);
} 
.bg-light-shade{
    background-color: var(--ion-color-light-shade);
} 
.bg-dark{
  background-color: var(--ion-color-dark);
}
.bg-primary{ 
  background-color: var(--ion-color-primary);
}
.bg-current{
  background-color: rgba(var(--ion-color-secondary-rgb), .3)
}
.bg-move{ background-color: rgba(var(--ion-color-success-rgb) , .4); }
.bg-idle{ background-color: rgba(var(--ion-color-warning-rgb) , .4); }
.bg-stop{ background-color: rgba(var(--ion-color-danger-rgb) , .4); }
.bg-offline{ background-color: rgba(var(--ion-color-medium-rgb) , .3); }
.bg-overspeed{ 
  background-color:  rgba(var(--ion-color-success-rgb), .4);
  border-top: 2px solid var(--ion-color-danger) ; 
  border-bottom: 2px solid var(--ion-color-danger) ; 
  /* background: rgb(255,0,0); */
  /* background: linear-gradient(0deg, rgba(var(--ion-color-danger-rgb),.4) 0%, rgba(249,160,76,1)7%, rgba(var(--ion-color-warning-rgb),.4) 24%, rgba(var(--ion-color-success-rgb) ,.4) 38%, rgba(var(--ion-color-success-rgb) ,.4) 100%); */
}
/* width */
::-webkit-scrollbar {
    width: 0px !important;
    border-radius: 50% !important;
}  
  /* Track */
::-webkit-scrollbar-track {
    background:  var(--ion-color-primary) ;
    border-radius: 15px;
    display: none;
    width: 0px !important;
} 
/* Handle */
::-webkit-scrollbar-thumb{
    /* background: rgba( var(--ion-color-primary-rgb) ) ; */
}
 
ion-toast{
    /* max-width: 20rem !important;
    transform: translateX(80vw) !important; */
}
/* #main{
  top: 3.3rem !important;
} */
.margin-main{
  top: 3.3rem !important;
}
.set-center-row{
  display: flex ;
  flex-direction: row  ;
  justify-content: center ;
  align-items: center ;
}
.flex-start{
  justify-content: flex-start ; 
  align-items:  flex-start ; 
}
ion-button{
  text-transform: none;
  text-decoration: none;
}

body.dark {
    --ion-color-primary: #FD5F06;
    --ion-color-primary-rgb: 253, 95, 6;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #fd4c06;
    --ion-color-primary-tint: #f27024;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body.dark {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body.dark {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }

  .ripple-parent {
    position: relative;
    overflow: hidden;
    border-radius: 0px;
  }
  
  ion-tab-bar[arail-os="mac"] ion-tab-button ion-icon,
  ion-tab-bar[arail-os="window"] ion-tab-button ion-icon {
    font-size:1.5em;
  }
  ion-tab-bar[arail-os="android"] ion-tab-button ion-icon,
  ion-tab-bar[arail-os="ios"] ion-tab-button ion-icon {
    font-size:1.5em;
  }
  ion-tab-bar[arail-os="mac"] ion-tab-button ,
  ion-tab-bar[arail-os="window"] ion-tab-button  {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  ion-tab-bar[arail-os="mac"] ion-tab-button ion-label,
  ion-tab-bar[arail-os="window"] ion-tab-button ion-label  {
    margin: 0px;
    margin-left: .5rem;
  } 
.page-content{
    width: 100%;
    height: 100vh;
    padding: 1.5rem;
    transition-duration: 300ms;
}

  .leaflet-container{ 
    width: 100%;
    height: 100vh !important;
}
.leaflet-container{
    width: 100% !important;
    /* min-height: 92.5vh;
    max-height: 100vh; */
    height: 100vh ;
    background-color: rgb(0, 0, 0, 0.1);
    transition-duration: 300ms; 
}
 
ion-button{
    max-height:2.5rem ;
}
 
ion-checkbox {
    --size: 17px;
    --checkbox-background-checked: var(--ion-color-light) ;
    --border-color-checked:  var(--ion-color-dark) ;
    --border-color:  var(--ion-color-dark) ;
    --checkmark-color:  var(--ion-color-primary) ;
    color: #000000;
}
  
ion-checkbox::part(container) {
    border-radius: 6px;
    border: 1px solid var(--ion-color-primary);
}
ion-input{
  --placeholder-color: var(--ion-color-dark) ;
  --color: var(--ion-color-dark) ;
  --highlight-color-focused: var(--ion-color-dark) ;
}

.text-bold{
  font-weight: bold !important;
}

.page-container{
  background-color: var(--ion-color-light-shade); 
  height: 98vh ; 
}
.page-container.with-toolbar{ 
  height:  93.5vh ; 
}
.tooltip {
    position: relative;
    display: inline-block;
    /* border-bottom: 1px dotted black; */
    z-index: 9999!important;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  /* .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px; 
    background-color: rgba( var(--ion-color-dark-rgb), .5);
    color: var(--ion-color-light);
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -60px; 
    z-index: 9999 !important;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 50%;
    margin-right: 5px;
    border-width: -5px;
    border-style: solid;
    z-index: 9999!important;
    border-color: var(--ion-color-medium) transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  } */
 
  ion-router-outlet{
    height: 100vh !important;
  }
  .markercluster-map{
    overflow-y: hidden !important;  
    margin: .3rem;
    height: 92vh !important; 
    border-radius: 10px;
  }

  ion-tab-button .current-tab{
    position: absolute;
    width: 30%;
    left: 35%;
    height: 3px;
    bottom: 0rem;
    border-radius: 25px;
    background: var(--ion-color-primary);
  }

  .popover-menu ion-item ion-icon{
    font-size: 1.3em;
    margin-right: .5rem !important;
  }
  .popover-menu ion-item ion-label{
    font-size: small !important;
  }
  .popover-menu ion-item{
    --background-hover: var(--ion-color-primary);
    --color-hover: var(--ion-color-primary);
  }


  ion-split-pane {
    --side-width: 300px;
    --side-max-width: 300px; 
  }


/* .col-side-menu{
  transform:translateX(-100px)
} */
.slide-right{animation:slide-right .8s cubic-bezier(.25,.46,.45,.94) both}
  /* ----------------------------------------------
 * Generated by Animista on 2023-9-30 15:8:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes slide-right{0%{transform:translateX(0)}100%{transform:translateX(15px)}}

.main-side-menu{ 
  box-shadow: 15px 4px 15px -14px rgba(0,0,0,0.03);
  border-right: 1px solid #EEE;
}
#map-geofences{  height:92vh!important;  }
#map-passroute{  height: 100%!important; }
#map-homescreen{  height: 100%!important; }
#ch-map{ height: 100% !important; border-radius: none !important;}
/* #map-homescreen.mobile{ height: 95vh!important;} */
#map-device-overspeed{  height: 20rem!important; }
.popup-device-overspeed{
  width:15rem !important;
  height:15rem !important;
}
.leaflet-control-attribution{
  display: none !important;
}
.set-center{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}