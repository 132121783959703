.modal-fake{
    width:100vw ;
    height:5rem ;
    z-index: 9999 !important;
    background-color: var(--ion-color-light); 
    position:absolute;
    bottom: 0;
    border-top-left-radius:15px;
    border-top-right-radius:15px;
}

.fake-bar{
    width: 2rem;
    height: .3em;
    background-color: var(--ion-color-medium-tint);
    position: absolute;
    top: 0;
    border-radius: 15px;
    margin-top: .3rem;

}