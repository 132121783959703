.group-side{ 
    background-color: var(--ion-color-light);
    height: 93vh;
    padding: 5px;
    padding: .5rem;
}

.inner-group{
    height: 80vh;
    background-color: var(--ion-color-light-shade);
    overflow-y: scroll;
    border-radius: 1rem;
}
.inner-group .list-device { 
    background-color: var(--ion-color-light);
    border-radius: 10px;
    width: 93% ;
    height: 82% ;
    border: 1px solid #DDD ;
    overflow-y: scroll 
}

@media only screen and (max-width: 480px) {
    .group-side{  
        height: fit-content;
        min-height: 10rem; 
    }
} 