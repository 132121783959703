#channals-map{
    width: 100% ;
    height: 90vh ;
    border-radius: 10px ;

}
#channals-map[aria-expanded="true"]{
    position: fixed; 
    z-index: 9999;
    left: -3.5vw;
    top: .5rem;
    min-height: 97vh;
    width:  98.7vw !important;
    border: 1px solid #DDD;
}
.map-channal{
    position:  relative ;
    width: 100%  ; 
    margin: .5rem ;
    border: 1px solid #DDD 
}
.leaflet-container{
    border-radius:  0px !important;
}

.map-channal.ch-1{ height: 80vh; }
.map-channal.ch-4{ height: 40vh; }
.map-channal.ch-9{ height: 25vh; }

#channals-map[aria-expanded="true"] .map-channal.ch-1{ height: 90vh; }
#channals-map[aria-expanded="true"] .map-channal.ch-4{ height: 43vh; }
#channals-map[aria-expanded="true"] .map-channal.ch-9{ height: 28vh; }