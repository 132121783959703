.map-dark.dark  .leaflet-layer,
.map-dark.dark  .leaflet-control-zoom-in,
.map-dark.dark  .leaflet-control-zoom-out, 
.map-dark.dark  .leaflet-control-attribution {
  filter: invert(100%) hue-rotate(180deg) brightness(110%) contrast(100%);
}

.leaflet-control .leaflet-control-layers-toggle  {
  background-color: var(--ion-color-light) !important;
  border-radius: 5px;
  /* background-image:url(https://cdn-icons-png.flaticon.com/512/2736/2736622.png) !important;   */
}
.leaflet-control {
  border-radius: 5px;
}
.leaflet-control .leaflet-control-layers-expanded  { 
  background-color: var(--ion-color-light) !important;
}
.layers-expand-control-btn{
  position: absolute ;
  top: 1rem ;
  right: 1rem ;
  z-index:999;
  display: flex ; 
}
.layers-expand-control, 
.layers-expand-full-control{
  position: absolute;
  z-index: 999;
  left: 1rem;
  bottom: 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
} 
.layers-expand-full-control{
  display:block; 
  width: 15rem;
  min-height: 15rem;
  /* padding: .5rem; */
  z-index: 999;
  background-color: #FFF;
}
.layers-expand-control .layer, .layers-expand-full-control .layer{
  width: 5rem;
  height: 5rem;
  margin: 0 .25rem 0 .25rem;
  background-size: cover;
  border-radius: 5px;
  border: 1px solid #DDD;
  cursor: pointer;
  -webkit-box-shadow: -1px 2px 15px -6px rgba(0,0,0,0.62);
-moz-box-shadow: -1px 2px 15px -6px rgba(0,0,0,0.62);
box-shadow: -1px 2px 15px -6px rgba(0,0,0,0.62);
}
.layers-expand-control .layers-tile-expand{
  display: flex;
  flex-direction: row;
  background-color: #FFF;
  padding: .5rem;
  border-radius: 5px;
  margin-left: 1rem;
  -webkit-box-shadow: -1px 2px 15px -6px rgba(0,0,0,0.62);
  -moz-box-shadow: -1px 2px 15px -6px rgba(0,0,0,0.62);
  box-shadow: -1px 2px 15px -6px rgba(0,0,0,0.62);
}
.layers-tile-expand .layer,
.layers-expand-full-control .layer{ 
  width: 3.3rem;
  height: 3.3rem;
  box-shadow: none;
  font-size: small;
}
.layers-tile-expand .layer:hover , 
.layers-expand-full-control .layer:hover {
  border: 2px inset var(--ion-color-primary);
} 
.layers-tile-expand .layer:hover .name ,
.layers-tile-expand .layer:hover ion-icon , 
.layers-expand-full-control .name ,
.layers-expand-full-control  .layer:hover ion-icon { 
  color: var(--ion-color-primary);
}
.layers-tile-expand .name,
.layers-expand-full-control .name {
  position:absolute;
  bottom:.25rem;
  margin-left: .5rem;
  color: #666;
  font-weight: 600;
} 
.activeArea { 
  position: absolute; 
  top: 50px; 
  left: 50px;
  right: 50px; 
  height: 200px; 
  z-index: 9999 !important;
  border: 1px solid #000;
}

.segment-zoom-control{
  position: absolute;
  z-index: 999;   
  right: .5rem;
  top: 5rem;   

  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 3rem !important; 
  background-color: var(--ion-color-light);
  border: 2px solid #CCC; 
  transition-duration: 100ms;
}

.segment-zoom-control .button-groups{
  width: 100%;
  flex-direction: column;
  display: flex; 
  width:  3rem !important;
  height: 3rem !important;
  padding: .1rem;
  color: var(--ion-color-dark) !important;
}
.button-groups button{ 
  color: var(--ion-color-dark);
}
.button-groups button ion-label,button-groups button ion-icon{
  font-size: 1.3em;
}
.button-groups button[aria-label="remove"]{
  visibility: hidden;
}
.button-groups button[aria-label="add"]{
  visibility: hidden;
}
.button-groups button:hover{ 
  background: radial-gradient(circle, rgba(var(--ion-color-primary-rgb),1) 15%, rgba(var(--ion-color-primary-rgb),0.2072072072072072) 43%, rgba(var(--ion-color-primary-rgb),0) 80%);
  color: #FFF;
}
.button-groups:hover{
  height: 9rem !important;
}
.button-groups:hover > button[aria-label="remove"] ,
.button-groups:hover > button[aria-label="add"]{
  visibility: visible;
}
.segment-zoom-control .button-groups  button{
  box-shadow: none !important;
  border-radius: 5px !important;
  width: 2.7rem !important;
  height: 2.7rem !important;
  background-color: var(--ion-color-light); 
}
 
.ripple {
  background-position: center;
  /* transition:  ripple  ease-in-out 0.8s; */ 
}
.ripple:hover {
  /* transition: ripple all     500ms; */ 
  /* animation: ripple .6s linear; */
  /* background: var(--ion-color-primary) radial-gradient(circle, transparent 1%, var(--ion-color-primary) 1%) center/15000%; */
}
button.ripple:active {
  background-size: 100%;
  animation: ripple .6s linear !important;
}
@keyframes ripple {
	 /* 0% {  background: radial-gradient(circle, rgba(19,73,133, 0.5) 0%, rgba(19,73,133,0) 37%);  }
	 50%  {  background: radial-gradient(circle, rgba(19,73,133, 0.6) 0%, rgba(19,73,133,0.01) 62%);  }
	 100% {  background:  radial-gradient(circle, rgba(19,73,133,1) 0%, rgba(19,73,133,0.5) 66%);} */
   100% {
    transform: scale(2);
    background-color: var(--ion-color-primary);
  }
}


#fasttrack-play-control{
    position: fixed;
    width: 25rem;
    height: 3rem;
    left:  40rem;
    z-index: 9999;
    border-radius: var(--ion-radius);
    flex-direction: row;
    font-size: large;
}
#fasttrack-play-control ion-range{
    top: -3rem;
    left: -.7rem;
    width: 89%;
}
.row-control{
    display: flex;
}
#fasttrack-play-control.hide{ 
    top: -30rem; 
    transition-duration: 300ms;
}
#fasttrack-play-control.hide.show{ 
    top: 2rem;
}
#fasttrack-play-control ion-grid{ 
    background-color: var(--ion-color-light); 
    border-radius: var(--ion-radius); 
}
.close-control{
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
    border-radius: var(--ion-radius);
    background-color: var(--ion-color-light); 
}

.pop-speed-trigger{
    --width:5rem;
    font-size:.8em;
}

ion-radio.radio-speed{
    --border-radius: 4px;
  --inner-border-radius: 4px;

  --color: #ddd;
  --color-checked:  var(--ion-color-primary);
}

ion-radio.radio-speed.ios {
  width: 15px;
  height: 15px;

  border: 2px solid #ddd;
  border-radius: 4px;
}

.radio-checked.radio-speed.ios {
  border-color:  var(--ion-color-primary);
}
#fasttrack-control{ 
    width:20rem;
    position:fixed;
    z-index:9999;
    background-color: var(--ion-color-light);
    box-shadow: var(--ion-box-shadow);
    border-radius: var(--ion-radius);
    top:3rem;
    left:50vw;
}
#fasttrack-control.hide{
    display: none;
}
#fasttrack-control.show{
    display: block;
}
.play-control-mobile{
  position: fixed;
  z-index: 999;
  width: 3.5rem ;
  background-color:#FFF;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  padding:.5rem; 
  margin: 1rem;
  border-radius: 10px;
  -webkit-box-shadow: 4px -2px 12px -1px rgba(0,0,0,0.2);
  -moz-box-shadow: 4px -2px 12px -1px rgba(0,0,0,0.2);
  box-shadow: 4px -2px 12px -1px rgba(0,0,0,0.2);
}
.play-control-float{
    min-width: 15rem;
    min-height: 10rem;
    z-index:9999;
    position:fixed;
    background: var(--ion-color-light);
    padding:.5rem;
    border-radius: var(--ion-radius); 
    box-shadow: var(--ion-box-shadow);
}
.play-control-float:active{
    cursor: grabbing;
}
.play-control-float .title{
    background-color: var(--ion-color-medium);
    border-radius: 5px;
    color: var(--ion-color-light);
    margin-bottom: .5rem;
    width: 100%;
}
.play-control-float .title ion-label{
    font-weight: bold !important;
    font-size: .7em;
}
.leaflet-popup .popup{
    font-family:  'Inter','Prompt', sans-serif; 
}
.popup-content{
    width: 15rem !important;
}
.popup.title{
    font-size: large;
    font-weight: bold;
}
.popup.speed{ 
    color: var(--ion-color-secondary);
    font-size: 1.1em;
}
.popup.street-view a{
    text-decoration: none;
    color: var(--ion-color-primary);
    font-size: 1.1em;
}
.popup.address{
    font-size: 1.1em;
    font-weight: 600;
}

.button-measure-distance  {
    width: 3rem;
    height: 3rem;
    background-color: var(--ion-color-light);
    color: var(--ion-color-medium);
    font-size: 1.3em;
    top: 5rem;
    right: .5rem;
    z-index: 999;
    position: absolute;
    cursor: pointer;
    border-radius: 5px !important;
    border: 2px solid rgba(var(--ion-color-dark-rgb) , .3);
}
/* .popover-measuerment{
  position: absolute;
  width: 17rem;
  padding: .5rem;
  z-index: 9999;
  top: 40vh !important;
} */
 /* .popover-measuerment{
    --width: 17rem; 
    --background: transparent;
    --backdrop-opacity: 0.01;
    --box-shadow: none;
    margin-top: .5rem;
}  
ion-popover .popover-measuerment::part(backdrop) {
  background-color: rgb(6, 14, 106);
} */
/*s
ion-popover.popover-measuerment {
   width: fit-content;
} */
.measurmement-control{   
    font-size: small;
}
.measurmement-control .top , .measurmement-control .bottom{
    padding: .5rem .5rem 0 .5rem;
    border-radius: 5px;
    background-color: var(--ion-color-light);
    border: 2px solid rgba(var(--ion-color-dark-rgb) , .3);
    font-size: .9em !important;
    margin-bottom: .5rem;
}
.measurmement-control .separate{
    width: 100%;
    height: 1px;
    border-top: 1px solid var(--ion-color-light-shade);
}
.add ion-icon{
    font-size: 2em; 
}
.measurmement-control .top ion-button {
    width: fit-content;
}
 
#menu-context{
    width:10rem;
    position:fixed;
    z-index:999;
    display:none;
    background-color: var(--ion-color-light); 
    border-radius: var(--ion-radius);
    box-shadow: var(--ion-box-shadow);
    cursor: pointer;
}
.button-icon-place{
    width: 3rem !important;
    height: 4rem !important;
    background-color: var(--ion-color-light);
    color: var(--ion-color-medium);
    font-size: 1.3em;
    top: 9rem;
    right: .9rem;
    z-index: 999;
    position: absolute;
    border-radius: 5px !important;
    border: 2px solid rgba(var(--ion-color-dark-rgb) , .3);
}
#map-route{ height: 100% !important; }
#map-route-add{ height:  80vh !important; }
#map-dashboard{
  height: 18rem !important;
}
#map-tracking.leaflet-container  {  
  height: 98vh !important; 
  margin-top: .5vh; 
  border-radius: none !important;
  margin-left: .5vw !important; 
  transition-duration: 300ms;
} 
#map-show-name{ height: 12rem !important; }
#map-setroute{ height: 91vh !important; }
#map-geozones{  height: 98vh !important; } 
#map-event{  height: 50vh !important; }
#multiple-vehicle-map{ height: 100% !important; }

.leaflet-tooltip.marker-place-name,
.leaflet-tooltip.polygon-place-name{
    font-family:  'Inter','Prompt', sans-serif !important;
    border: none;
    background-color: rgba(var(--ion-color-dark-rgb) ,0);
    box-shadow: none; 
    font-weight: 600;
    bottom: -2.7rem;
    left: .25rem;
    padding: 3px;   
    background-color: var(--ion-color-light);
    border-radius: 10px;
    padding: 5px  ;
    box-shadow: -2px 7px 35px -12px rgba(0,0,0,0.75);
}
.marker-img-divicon{ 
    z-index: 9999;
    border-radius: 50% !important;  
   height:5px;
   border-radius:50% ; 
   background-size:cover;
   background-position: center center; 
} 
.marker-status-divicon{ 
    z-index: 9999;
    border-radius: 10% !important; 
    transform-origin: center; 
  }
.marker-status-divicon::before{
    /* content: url('../../public/assets/icon/circle-up-regular.svg') ;  */
  }
  .marker{
    position: absolute;
    left: 45%;
    top: -70%;
    color: var(--ion-color-success);
    font-size: 2em;
    background-color: var(--ion-color-light);
    border-radius: 50%;
    box-shadow: -1px -1px 7px 5px rgba(50, 231, 34, 0.25);
  }
  .marker.sts{
    color: var(--ion-color-primary);
    background-color: var(--ion-color-light);
    box-shadow: -1px -1px 7px 5px rgba(var(--ion-color-primary-rgb), 0.25); 
  }
  .marker.sts-7{
    color: var(--ion-color-success);
    background-color: var(--ion-color-light);
    box-shadow: -1px -1px 7px 5px rgba(var(--ion-color-success-rgb), 0.25);
  } 
  .marker.sts-23{
    color: var(--ion-color-danger);
    background-color: var(--ion-color-light);
    box-shadow: -1px -1px 7px 5px rgba(var(--ion-color-danger-rgb), 0.25); 
  } 
  .marker.sts-24{
    color: var(--ion-color-warning);
    background-color: var(--ion-color-light);
    box-shadow: -1px -1px 7px 5px rgba(var(--ion-color-warning-rgb), 0.25); 
  }
  .marker.sts-0{
    color: var(--ion-color-medium);
    background-color: var(--ion-color-light);
    box-shadow: -1px -1px 7px 5px rgba(var(--ion-color-medium-rgb), 0.25); 
  }
  .marker.select{ 
    animation: bounce2 2s ease infinite, hidden 0s ease-in 10s forwards;;
  }
  .marker.select{ 
    animation: bounce2 2s ease infinite, hidden 0s ease-in 10s forwards;;
  }
  .bounce2 {
    animation: bounce2 2s ease infinite;
  }
  @keyframes bounce2 {
      0%, 20%, 50%, 80%, 100% {transform: translateY(0);
      animation-delay: 250ms;}
      40% {transform: translateY(-30px);}
      60% {transform: translateY(-15px);}
  }
  @keyframes hidden {
    to {
        width:0;
        height:0;
        overflow:hidden;
        display: none;
        box-shadow: none;
    }
  }
  
  .marker-move,.marker-7{
    background-color: var(--ion-color-success);
    animation-name: color;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  .marker-stop,.marker-23{
    background-color: var(--ion-color-danger);
    border-radius: 50% !important;
  }
  .marker-move-slow {
    background-color: var(--ion-color-warning-tint);
  }
  .marker-idle,.marker-24{
    background-color: var(--ion-color-warning);
  }
  .marker-offline,.marker-0{
    background-color: var(--ion-color-medium);
  }
  .marker-overspeed{
    background-color: var(--ion-color-success);
    border: 2px solid var(--ion-color-danger);
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  
  @keyframes color {
    0% {
      background-color: var(--ion-color-success-shade);
    }
    50% {
      background-color: var(--ion-color-success);
    }
    100% {
      background-color: var(--ion-color-success-shade);
    }
  }  
  
  .text-move{color: #25e310}
  .text-idle{color: #ffea03;}
  .text-stop{color:  #ff2403 ;}
  .text-offline{color: var(--ion-color-dark);}

  .realtime-point-marker{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #FFF;
    box-shadow: 1px 0px 22px -3px rgba(0,0,0,0.47);
  }
  .leaflet-popup-content-wrapper{
    font-family:  'Inter','Prompt', sans-serif !important;
    background-color: var(--ion-color-light);
    color:var(--ion-color-dark);
  } 
  
  /*------------------ Markers ---------------------------*/
  .pin1 {
    position: absolute;
    top: 40%;
    left: 50%;
    margin-left: -115px;
    
    border-radius: 50% 50% 50% 0;
    border: 4px solid #c73f0e;
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
  }
  
  .pin1::after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    background-color: #c73f0e;
  }
  
.pin2 {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: 115px;
  
  border-radius: 50%;
  border: 8px solid var(--ion-color-danger);
  width: 8px;
  height: 8px;
}

.pin2::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  bottom: -30px;
  left: -6px;
  border: 10px solid transparent;
  border-top: 17px solid var(--ion-color-danger);
}

.control-draw-geofences{
  position:absolute; 
  z-index: 9999 !important;
  background-color: var(--ion-color-light);
  left: 1rem;
  top:  4rem;
  height: 10rem;
  border-radius: 5px;
  -webkit-box-shadow: -2px 5px 19px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: -2px 5px 19px -8px rgba(0,0,0,0.75);
  box-shadow: -2px 5px 19px -8px rgba(0,0,0,0.75);
  border: 1px solid var(--ion-color-primary);
}
.control-draw-geofences{
  background-color: var(--ion-color-light);
  border: 2px solid #CCC;
} 
.control-draw-geofences  button{
  width: 2.3rem;
  height: 2.3rem;  
  padding: none !important;
  font-size: 1.2em !important;
  color: var(--ion-color-dark);
  background-color: var(--ion-color-light);
  border-radius: 5px; 
}
.control-draw-geofences  button:hover{
  color: var(--ion-color-danger);
}
 
.button-ready-draw{
  width: 2.9rem;
  height: 2.9rem;
  padding: .7rem;
  position:absolute;
  z-index: 9999;
  background-color: var(--ion-color-light);
  border-radius: 5px;
  left: 1rem;
  top: 2rem;
  border-radius: 5px;
  -webkit-box-shadow: -2px 5px 19px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: -2px 5px 19px -8px rgba(0,0,0,0.75);
  box-shadow: -2px 5px 19px -8px rgba(0,0,0,0.75);
  border: 1px solid var(--ion-color-primary);
  color: var(--ion-color-primary);
  font-size: 1.3em;
}

/******************* clustergroup *************************/
 
  .cluster-outer.small{
    width:  2.5rem;
    height: 2.5rem; 
    background-color: rgba( var(--ion-color-warning-rgb) , .2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family:  'Inter','Prompt', sans-serif;
    border-radius: 50% !important;
  } 
  .cluster-outer.small .cluster-inner{
    width:  1.8rem;
    height: 1.8rem;
    border-radius: 50% !important;
    color: #4d4d4d;
    background-color: rgba( var(--ion-color-warning-rgb) ,.6);
    font-weight: bolder;
  }

  .cluster-outer.large{ 
    width:  4rem;
    height: 4rem; 
    border-radius: 50% !important;
    background-color: rgba( var(--ion-color-secondary-rgb) , .1) !important;
  }
  .cluster-outer.large .cluster-inner{
    width: 2.5rem!important;
    height:2.5rem!important; 
    border-radius: 50% !important;
    color: #FFF;
    background-color: rgba( var(--ion-color-secondary-rgb) , .6) !important;
  }

  
  .toolbar-map{
    width: fit-content;
    height: 2.5rem;
    position: absolute;
    z-index: 999;
    top: 0px;
    right: 10rem;
    background-color: var(--ion-color-light);
    flex-direction: row;
    display: flex;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.07);

  }
  .toolbar-map-inner{
    width: 3rem;
    height: 100%;
    color: var(--ion-color-medium-shade);  
  }
  .toolbar-geofence-popup{
    --width: 15rem;
  }
  .toolbar-geofence-popup-content{
    background-color: var(--ion-color-light-tint);
    border-radius: 5px; 
    height: 20rem;
    overflow-y: scroll;
  }
  .toolbar-geofence-popup ion-item{
    font-size: .8em !important;
    border-bottom: 1px solid #EEE;
  }
  .toolbar-geofence-popup ion-item ion-icon{
    font-size: 1em;
  }
  .toolbar-geofence-popup ion-item ion-img{
    width: 1.5em;
    margin-right: .3rem;
  }

  .popup-focus-marker{
    --width: 20rem;
  }

  /***********************************************************/

  .btn-ready-draw,  .btn-edit-draw , .btn-del-draw, .btn-show-radar{
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 9999;
    background-color: var(--ion-color-light);
    border: 2px solid #CCC;
    padding: .7rem;
    border-radius: 5px !important;
    cursor: pointer;
    font-size: 1em;
  }
  .btn-edit-draw { 
    top: 4rem;
  }
  .btn-del-draw{  
    background-color: var(--ion-color-danger);
    top: 7rem;
  }
  .btn-show-radar{ 
    top: 10rem;
    color: var(--ion-color-primary) !important;
  }
  .icon-place{
    top: 1rem;
    left:  4rem;
  }
  .btn-ready-draw.active,  .btn-edit-draw.active , .btn-del-draw.active, .btn-show-radar.active{
    border: 2px solid var(--ion-color-primary);
    color: var(--ion-color-primary); 
  }

  @media only screen and (max-width: 420px) {
    .btn-ready-draw { 
      top: 6.5rem;
    }
    .control-draw-geofences{
      top: 10rem;
    }
  }
  .marker-div-draw{ 
    z-index: 9999;
    border-radius: 50% !important; 
    transform-origin: center;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--ion-color-light);
    box-shadow: 0px 2px 30px -10px rgba(0,0,0,0.46);
    border: 1px solid var(--ion-color-dark-tint);
  }
  .marker-squre-draw{
    background-color: var(--ion-color-dark);
    width: 10px;
    height: 10px;
    margin:20px auto; 
    transform:rotate(45deg) !important;
  }
  .popup-markroute-menu{
    font-size: small !important;
  }

  .popup-chip-share{
    width: fit-content;
    padding: 5px  .7rem 5px .7rem;
    border-radius: 20px;
    background-color: rgba(var(--ion-color-secondary-rgb), .1);
    cursor: pointer;
    margin-top: .5rem; 
  }

  .popup-chip-share:hover{ 
    background-color: rgba(var(--ion-color-secondary-rgb), .2); 
  }

.leaflet-tooltip-left:before {
  background-color: transparent;
}

.leaflet-tooltip.leaflet-tooltip-geozone{
  background-color: transparent !important;
  color:black ;
  border: none;
  box-shadow: none;
  /* -webkit-text-stroke: .1px #FFF;  */
  font-family:  'Inter','Prompt', sans-serif;  
  font-weight: bold !important;
  /* text-shadow: -1px 0px 5px rgba(255,255,255,1); */
  text-shadow:1px 1px 10px #fff, 1px 1px 10px #ccc;
  /* text-shadow: -2px 0px 5px rgba(0,0,0,0.3); */
  letter-spacing: 1px;
  /* background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(248,248,248,0.7) 9%, rgba(251,251,251,0.09) 30%, rgba(255,255,255,0.02) 70%); */
}
.leaflet-tooltip-geozone.leaflet-tooltip-top:before,
.leaflet-tooltip-geozone.leaflet-tooltip-bottom:before,
.leaflet-tooltip-geozone.leaflet-tooltip-left:before,
.leaflet-tooltip-geozone.leaflet-tooltip-right:before {
    position: absolute;
    pointer-events: none;
    border: 6px solid transparent;
    background: transparent;
    content: "";
    }
 