
.sinin-page{
    background-color: rgb(0, 0, 0);
    position: relative;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: right top;
    transition-duration: 500ms;
}
.sinin-page .wraper{
    width: 100%;
    height: 100vh;
    background: linear-gradient(81deg, rgba(0,0,0,0.9) 3%, rgba(0,0,0,0.7) 70%, rgba(0,0,0,0.3) 100%);
}
.signin-container{
    position: absolute;
    z-index: 999;
    max-width: 25rem;
    min-height: 30rem;
    background-color: var(--ion-color-light);
    padding: 1.5rem;
    border-radius: 15px;
    left: 3rem;
}
.form-description{
    font-size: .8em;
    color: var(--ion-color-dark);
    font-weight: bold;
    padding-left: 0px;
}
.form-title{
    font-size: 2.6em; 
    font-weight: bolder;
    padding-left: 0px;
    color: var(--ion-color-secondary);
    font-weight: 900 !important;
    font-family: 'Inter';
}
.form-title ion-icon{
    font-size: .4em;
    margin-left: -.7em;
    margin-bottom: -.2em;
}

.input ion-item ion-label{
    font-size: .7em !important;
}
.input ion-item  .native-input{
    padding: 0px !important;
    font-size: medium;
}
.input{
    background-color: var(--ion-color-light-shade);
    border-radius: var(--ion-radius);
    margin-bottom: .5rem;
    padding-left: .5rem;
}
.input ion-item  ion-input{
    height: 1rem !important;
}
.public-nav{
    position: absolute;
    top: 1rem; 
    left: 1rem; 
}
.public-nav ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.public-nav ul li {
    margin-right: 4rem;
    cursor: pointer;
    border: 2px solid transparent;
    padding: .4rem;
    border-radius: 5px;
    transition-duration: 300ms;
    color: #FFF;
}
.public-nav ul li:hover { 
    /* border: 1px solid  var(--ion-color-primary);  */
    border-color: var(--ion-color-primary);
    color: var(--ion-color-primary) !important; 
} 
.public-nav ul li a{
   text-decoration: none;
}
.public-nav ul li ion-icon{
    font-size: larger;
    margin-bottom: -.2rem; 
}
.reserve-terms{
    position: absolute;
    color: rgb(177, 177, 177);
    bottom: 3rem;
    right: 3rem;
    font-size: .8em;
    font-weight: 400 !important;
}
.reserve-terms .terms{
    color: var(--ion-color-primary);
    font-weight: bolder;
    border-bottom: 1px solid var(--ion-color-primary);
}

.services-list ion-item{
    font-size: smaller !important;
}
.services-list ion-item:hover{
    color: var(--ion-color-secondary-shade);
    cursor: pointer;
    --background: #DBDBDB !important;
}
.nest-option{
    top: 0;
    left: .2rem !important;
}



@media only screen and (max-width: 480px) {
    .signin-container{
        position: absolute;
        z-index: 999;
        width: 80vw !important;
        min-height: 25rem;
        background-color: #FFF;
        padding: 1.5rem;
        border-radius: 15px;
        left: 2.5rem;
    }

    .form-title{
        font-size: 1.6em; 
        font-weight: bolder;
        padding-left: 0px;
        color: var(--ion-color-secondary);
    }
    .form-title ion-icon{
        font-size: .4em;
        margin-left: 0;
        margin-bottom: 0;
    }

    .public-nav{
        position: absolute;
        top: 1rem; 
        left: .2rem; 
    }
    .public-nav ul{
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .public-nav ul li {
        margin-right:1rem;  
        font-size: .7em;
    }
 

    .reserve-terms{ 
        left: 1rem;
        font-size: .6em;
        font-weight: 400 !important;
        bottom: .5rem;
        width: 98vw;
    } 
    
} 