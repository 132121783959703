.notification-content{
    background-color: var(--ion-color-light-tint);
}
.btn-add-notification{
    border-radius: 2px 2px 9px 9px;
    background: #FC7328;
    box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.25);
    position: absolute;
    width: 2rem;
    height: 2.5rem;
    top: 2.5rem;
    left: 20%;
    z-index: 5;
    font-size: 1.5em;
}
.btn-add-notification ion-ripple-effect{
    border-radius: 2px 2px 9px 9px; 
}

.notification-way {
    display:flex ;
    align-items:center;
    font-size: small;
    margin-right: .5rem;
}
.col-audio audio{
    background-color: transparent !important;
    filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%) !important;
}
.col-audio audio::-webkit-media-controls-enclosure {
    border-radius: 5px;
    background-color: transparent;
}
.col-audio audio::-webkit-media-controls-play-button{
    /* filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%) !important;   */
    color: green !important;
}

select#alarm-sound{
    padding: .5rem;
    border-radius: 5px;
    background-color: var(--ion-color-light-shade);
    border: none;
    margin-bottom: 1rem;
    font-size: .9em;
}