#modal-dialogroute{
    --width: 97vw !important;
    --height: 97vh !important;    
}

.group-toolbar{
    width: 100% ;
    padding: .2rem ; 
    min-height: 2.5rem ;
    position: absolute  ;
    bottom: 0px ;
    z-index: 999 ;
    justify-content: space-between ;
}
.passroute-details-dialog{
    height: 24vh ;
    overflow: scroll 
}
.passroute-details-dialog::-webkit-scrollbar {
    width: 20px;
  }
  
.passroute-details-dialog::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  
  /* Handle */
.passroute-details-dialog::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
  } 