.apexcharts-canvas text{
  color: var(--ion-color-dark) !important;
}
.apexcharts-text apexcharts-yaxis-label {
  color: var(--ion-color-dark) !important;
}
#chart-violation-overspeed,
#chart-violation-line{
  background-color: var(--ion-color-light) !important;
}
.ion-content-scroll-host {
    position: absolute;
    top: 0;
    left: 0; 
    height: 100%;
    width: 100%;
    padding-top: 1rem;
    overflow-y: auto;
  }
  .dashboard-ground{
    width:100%;
    height:100%;
    overflow-y:scroll;
    background-color: var(--ion-color-light);
    border-radius:5px;
  }
  .sum-current-event{
    background-color:var(--ion-color-light-tint);
    padding: 1rem;
    border-radius: 5px;
  }

  #dashboard-content{
    /* padding: 0rem  3rem 0rem  3rem ; */
    height: 100vh;
    padding-bottom: 2.5rem;
  }

  #dashboard-content ion-title{ 
    font-size: 2em;
    font-weight: 600;
    padding: 0;
  }
  #dashboard-content sub.sub-title{
    color: var(--ion-color-dark-tint);
  }

  ion-title.sub-title{
    font-size: 1.5em !important;
  }
  .widget{ 
    background-color: var(--ion-color-light);
    height: 10rem;
    width: 100%;
    box-shadow: var(--ion-box-shadow) ;
    border-radius: var(--ion-radius);
    padding: 1rem;
    margin-right: 2rem;
    position: relative;
  }
  .widget ion-col{ 
    align-items: flex-start;
    height: 100%;
  }
  .can-move{
    cursor:move;
  }
  .widget .name{
    position: absolute;
    top: 1rem;
  }
  .widget .value{
    position: absolute;
    bottom: 0 !important;
    font-size: 4em;
    margin-left: 1rem;
    font-weight: 800; 
  }
  .widget .icon{
    position: absolute;
    top: 1rem;
    max-width: 7rem;
    text-align: center;
    font-size: 3.5rem; 
  }
  .widget .update{
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: right;
    color: var(--ion-color-medium); 
  }
  .widget .update small{
    font-weight: bold;
    font-size: .6em;
    color: var(--ion-color-success);
  }
  #widget-event{background-color: transparent !important;}
  #widget-event  .event-list{
    padding: .3rem;
    border-radius: var(--ion-radius) ;
    margin-bottom: .5rem;
    font-size: .8em;
    background-color: var(--ion-color-light);
  }
  #widget-bar-chart {}
  .wrap-back{
    position: absolute;
    width: 95%;
    height: 100%;
    opacity: .5;
    top: 0;
    font-size: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .wrap-back ion-img{
    height: 7rem;
  }
  .widget .other-data{
    position: absolute;
    bottom: 1rem;
  }

#fleet-idle{
  font-size: small;
  width: 100% !important;
  min-height: fit-content !important;
  max-height: 20rem !important;
  padding-bottom: 1.5rem !important;
}
#fleet-idle.widget{
  margin: 0;
}
#fleet-idle small{
  font-size: .7em;
}
.feet-idle{
  font-weight: bold; 
  color: var(--ion-color-warning);
  font-size: 1.5em;
  margin-left: .3em;
}

.feet-wast{
  font-weight: bold; 
  color: var(--ion-color-danger); 
  font-size: 1.5em;
  margin-left: .3em;
}
#widget-circle-chart .update{
  position:absolute;
  display: flex;
  flex-direction: column;
  font-size: .8em;
  justify-content: flex-end;
  align-items: flex-end;
  right:20%;
  bottom: 1rem;
  color: var(--ion-color-medium);
} 

.drag-map-dahboard{
  position:absolute;
  z-index:999 ;
  width:100%;
  padding:.5rem; 
  border-top-right-radius:10px;
  border-top-right-radius:10px;
}

.drag-map-dahboard .enable{background-color:"rgba(0,0,0,.1)"}
.drag-map-dahboard .disable{background-color:"rgba(0,0,0,0.3)"}


.dashboard-modal{
  position:fixed;  
  background: var(--ion-color-light); 
  border-radius: var(--ion-radius);
  padding:1rem;
  -webkit-box-shadow: -1px -2px 20px -4px rgba(0,0,0,0.24);
  -moz-box-shadow: -1px -2px 20px -4px rgba(0,0,0,0.24);
  box-shadow: -1px -2px 20px -4px rgba(0,0,0,0.24);
} 

@media only screen and (max-width: 480px) {
  .dashboard-modal{
    width: 100vw;
    height: 90vh;
    bottom: -2rem !important; 
  } 
  .feet-idle,.feet-wast{ 
    font-size: 2.5em !important;
    margin-left: .3em;
  } 
}
@media only screen and (min-width: 720px) {
  .dashboard-modal{
    width:70vw;
    min-height:70vh;
    left:15vw;
  top:10vh;
  } 
}


.dashboard-title{
  padding-left: 0px;
  font-weight: bold;
  font-size: 1.5em; 
}
.dashboard-sub-title{

}
.dashboard-date{
  float: right;
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center; 
}
.dashboard-date  .date{
  margin-right: .5rem;
}
.dashboard-date .icon-calendar{
  width: 2.5rem;
  height: 2.5rem;
  background: var(--ion-color-light-shade);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
 .icon-calendar ion-icon{
  color: var(--ion-color-dark);
  font-size: larger; 
}
.card-status{
  height: 6rem;
  display: flex;
  flex-direction: row; 
}
.card-status .device-number{
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: var(--ion-color-light-shade);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  font-weight: bold;  
  color: var(--ion-color-medium-shade);
}
.card-status .device-status{
  width: 100%;   
}
.card-status img{
  height: 75% !important;
}
.card-status .status-name{
  font-weight: bold;
  font-size: small;
  top: -1rem;
}
.dashboard-side-menu{
    background-color: var(--ion-color-light);
}

.main-dashboard::-webkit-scrollbar {
  width: 0px;
} 

.dashboard-card{
  border-radius: 20px;
  background-color: var(--ion-color-light-tint);
  padding: 1rem;
}



ion-segment.status-device{padding-left: .1rem; background-color: var(--ion-color-light) }
ion-segment.status-device ion-segment-button{padding-top: .5rem; --background: var(--ion-color-light) }
ion-segment.status-device ion-segment-button ion-icon{
  font-size: 1.3em;
}
.status-device ion-segment-button{
  color:  rgba(var(--ion-color-medium-rgb), .7)
} 
ion-segment.status-device ion-segment-button.segment-button-checked{
    font-weight: bold;
    color: var(--ion-color-primary);
}
ion-segment.status-device ion-segment-button  ion-label{
  font-size: .9em !important;
}
ion-segment.status-device ion-segment-button  ion-icon{
  font-size: 1.7em !important;
}
ion-segment.status-device ion-segment-button.segment-button-checked:hover{
  font-weight: bold;
  color: rgba(var(--ion-color-primary-rgb), .7) !important;
}
ion-segment-button.segment-button-checked{
  border-radius: 20px !important ;
} 
ion-segment.status-device ion-segment-button{
  max-width:  6.5rem;
}