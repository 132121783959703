ion-menu::part(backdrop) {
    background-color: transparent !important;
}
  
ion-menu::part(container) {
    border-radius: 20px 20px 20px 20px !important;
  
    /* box-shadow: 4px 0px 16px rgba(255, 0, 255, 0.18); */
}
ion-menu.main-menu::part(backdrop) { 
    padding-top: 0px !important;
}

ion-menu.main-menu::part(container) { 
    margin: .5rem; 
    padding-top: 0px !important;
    padding-bottom: 2.5vh !important;
    z-index: 9999!important;
    /* height: 90vh;
    top: 2.5vh; */
}

.header-menu{
    width: 100%;
    height: 10rem;  
    top:  -.8rem;
    background-position: center right;
    background-size: cover;
    transform: scaleX(-1);
    position: relative;
    border-radius: 20px;
}

.header-menu .inner-header{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 19px; 
    background: 
    linear-gradient(313deg, 
        rgba(var(--ion-color-light-rgb), 0.93) 44.76%, 
        rgba(var(--ion-color-light-rgb), 0.67) 76.32%, 
        rgba(var(--ion-color-light-rgb), 0.00) 100%
    );
 
}

.header-menu  .front-menu{
    padding: 1rem;
    position: absolute; 
    transform: scaleX(-1);
}
.header-menu  .front-menu ion-img{
    width: 85%;
}
 
.header-menu  .front-menu .theme-toggle{
    margin-top: .5rem;
}
 ion-segment#themeSegment{
    background-color: var(--ion-color-light-shade);
}
ion-segment#themeSegment ion-segment-button{
    border-radius: 30px !important;
    color: var(--ion-color-dark-tint) !important;
}
ion-segment#themeSegment ion-segment-button ion-icon{
    font-size: 1.35em !important;
}
ion-segment#themeSegment ion-segment-button ion-label{
    /* font-size: .9em !important; */
}
.header-menu  .profile-header{
    position: absolute; 
    width: 80%;
    min-height: 80%;
    bottom: -37%;
} 
.header-menu  .profile-header ion-avatar{
    width: 5em;
    height: 5em; 
    box-shadow: -1px 2px 22px -6px rgba(0,0,0,0.3);
    margin-bottom: .5rem;
}
.header-menu  .profile-header ion-label{ 
    transform: scaleX(-1);
}
.header-menu  .profile-header ion-label.version{
    font-size: .7em;
    color: var(--ion-color-medium);
} 
ion-item.menu-page{
    color: var(--ion-color-dark) !important;
    border-radius: 10px !important; 
    --color-hover: var(--ion-color-primary) !important;
}
ion-item.menu-page ion-icon ,ion-item.menu-page svg,  ion-item.menu-icon{
    color: var(--ion-color-dark-tint) !important;
}
ion-item.menu-page:hover{
    color: var(--ion-color-primary) !important;
    cursor: pointer;
}
ion-item.menu-page:hover ion-label,ion-item.menu-page:hover ion-icon ,ion-item.menu-page:hover svg{
    color: var(--ion-color-primary) !important;
    cursor: pointer;
}
ion-item.menu-page.active{
    background-color: rgba(var(--ion-color-primary-rgb), .05) !important;
    border-left: 2px solid var(--ion-color-primary);
}

.sub-menu-header.active span, .sub-menu-header.active ion-icon{ 
    color: var(--ion-color-primary) !important;
}
ion-item.menu-page .menu.active, 
.menu.active ion-icon ,
.menu.active svg  {
    color: var(--ion-color-primary) !important;
}


.menu-page ion-label.title-menu{
    font-size: .8em !important; 
}

/* 
.dott{
    z-index: 999 !important;
    position: absolute;
    width: .2rem;
    background-color: var(--ion-color-primary);
    height: 50%;
    left: 0px !important;
    border-radius: 5px;
} */
ion-item.menu-page .dott{
    left:  -.5rem !important;
    z-index: 999 !important;
    position: absolute;
    width: .2rem;
    background-color: var(--ion-color-primary);
    height: 50%;
    left: 0px !important;
    border-radius: 5px;
}

.menu-user-bottom{
    position: absolute;
    bottom: 5rem;
    width: 80%;
    left: 10%;
    height: 3rem;
     
    padding: .2rem;
    
    border-radius: 14px;
    background-position: center bottom;
    background: url("../../../public/assets/img/long-promthep.jpg"), lightgray 30% / cover no-repeat;
    background-repeat: no-repeat;
 
    background-size: cover;
}
.menu-user-bottom .content{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 14px;
    color: var(--ion-color-dark);
    
    width: 100%; 
    background-color: rgba( var(--ion-color-light-rgb), .5);  
}
.menu-user-bottom .content ion-avatar{
    width: 38px;
    height: 38px; 
}
.menu-user-bottom .content .version{
    margin: 0;
    font-size: .6em;
}
.menu-user-bottom .content .btn-logout{ 
    height: 2.2rem;
    width: 2.2rem;
    border-radius: 50%;
    font-size: 1.2rem;
    color: var(--ion-color-dark);
    background-color: rgba( var(--ion-color-light-rgb), .7);  
}
ion-accordion  ion-item.sub-menu-header {
    width: 100% !important;
}
ion-accordion  ion-item.sub-menu-header ion-icon{
    font-size: .8em !important; 
}

.accordion-group-menu{
    width: 100% !important;
}
 
.sub-menu-header::part(native){
    padding-left: 0px !important;
}
.callapse-menu  { 
    height: 100%; 
    position: relative;
}
.callapse-menu  .avatar{
    width: 2rem !important;
    height: 2rem !important;
    background-size: contain;
}
.callapse-menu  ul{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
}
.callapse-menu  ul li{
    list-style: none;
}

.callapse-menu ul.end li.signout{ 
   background-size: cover;
   border-radius: 50%;
   padding: 0px;
   width: 3rem;
   height: 3rem;
}
.callapse-menu ul.end li.signout  button{ 
   background-color:  rgba( var(--ion-color-light-rgb), .5);
   height: 100%;
   width: 100%; 
   background-position: right center;
   background-size: contain;
   background-position-x: 90%;
   font-size: 1.2em !important;
   padding: none !important;
}
.callapse-menu ul.end{ 
    /* height: 50%; */
    /* display: flex;
    flex-direction: row;
    align-items: flex-end; */ 
    bottom: 0;
    /* position: absolute; */
}
@media only screen and (max-width: 800px) {
    .callapse-menu  {
      background-color: lightblue;
    }
  }
.menu-group.open{
    background-color: var(--ion-color-light-tint);
    border-radius: 15px;
} 
.menu-group.open ion-item-divider{ 
    padding: .5rem 0 .5rem;
    background-color: var(--ion-color-light-shade) !important;
    border-radius: 15px;
}
.popover-profile{
    --width: 18rem;
}
.popover-profile .popover-arrow{
    top:  -5rem !important;
}
.popover-profile ion-avatar.person{
    position: absolute ;
    bottom: -1.5rem;
    background-color:#E1E1E1;
    width: 4rem;
    height: 4rem;
    padding: 0px !important;
}
.popover-profile ion-avatar.person ion-img{
    width: 100%;
}

.popover-profile ion-item ion-avatar.icon{
    margin-right: .5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #e1e1e1;
}
.popover-profile ion-item ion-label{
    font-size: .8em !important;
}
.popover-profile ion-item {
    background-color: #F8F8F8 ;
    border-radius: 15px;
    margin-bottom: .5rem;
}
