.list-header ion-label small{
    font-size: .6em;
    font-weight: bold;
}

.list-dev-wrapper{
    border-radius: var(--ion-radius);
    border: 1px solid #ccc;
    margin-bottom: .5rem;
    transition-duration: 1s;
    position: relative;
}
.next-btn{
    font-size: 1rem;
}
.preview-device{
    position: absolute;
    background-color: var(--ion-color-light);
    width: fit-content;
    height:fit-content; 
    right: .6em;  
    width: 2rem;
    height: 2rem;
    z-index: 9999;
    border-radius: var(--ion-radius);
    border: 1px solid #ccc; 
    /* visibility: hidden;  */
    cursor: pointer;
}
.preview-device:hover{
    background-color: var(--ion-color-primary);
    color: var(--ion-color-light);
}
.list-dev-wrapper:hover > .preview-device{
    visibility: visible;
}
.list-dev{
    padding: .2rem;
    min-height: 3rem;
}

.status-pulse-name{
    display: flex;
    flex-direction: row;
}
.status-pulse-name ion-img{
    width: 1.2rem;
    margin-right: .2rem;
    transition-duration: 150ms;
    cursor: pointer;
}
.status-pulse-name ion-img:hover{
    width: 1.5rem; 
}
.status-pulse-name ion-label{
    font-size: .7em;
    font-weight: bold;
    color: var(--ion-color-dark);
    cursor: pointer;
}
.status-pulse-name ion-label.offline{ 
    color: var(--ion-color-medium);
}
.status-pulse-name ion-label.move{ 
    color: var(--ion-color-success);
}
.status-pulse-name ion-label.stop{ 
    color: var(--ion-color-danger);
}
.status-pulse-name ion-label.idle{ 
    color: var(--ion-color-warning);
}

.status-gps-state{
    display: flex;
    flex-direction: row; 
    /* border-radius: 50%; */
    transition-duration: 300ms;
    padding: .5rem;
    cursor: pointer;
}

.status-gps-state ion-img{ 
    width: .5rem;
}
/* .status-gps-state:hover {
    box-shadow: -2px 6px 24px -12px rgba(0,0,0,0.73); 
} */