 
.nav-toolbar{
  --background: #FFF;
  /* border-bottom: 1px solid #DDD; */
}
.nav-bar{
    display: flex ;
    flex-direction: row;
    margin-bottom: .3rem !important;
    margin-top: .3rem !important;
    float: left;
    width: 50%; 
} 
.nav-bar.end{
    float: right; 
    width: 50%;
    display: flex;flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}
.nav-bar li{
    list-style: none;
    margin-right: 2rem; 
}
/* .nav-bar li  button  ion-label,.nav-bar li button ion-label{
    font-size: .9em;
}  */
.nav-bar li  button   {
    padding: .4rem;
    background-color: transparent; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.nav-bar li  button ion-label{
    font-size: .9em !important;
    margin-left: .2rem !important;
}

.nav-bar li  button.menu svg ,
.nav-bar li  button.menu ion-icon{
    font-size: 1.2em !important;
    color: var(--ion-color-dark-tint) !important;
    transition-duration: 300ms;
}
.nav-bar li  button.menu svg ,
.nav-bar li  button ion-label{
    color: var(--ion-color-dark-tint) !important;
    font-size: 1em;
    text-transform: none;
}
.nav-bar li  button.home{
    width:  5rem; 
    justify-content: center;
    align-items: center;
    font-size: .9em !important;
    padding: .4rem;
    border: 1px solid var(--ion-color-primary);
    border-radius: 12px;
    background-color: transparent  ;
    color: var(--ion-color-primary) !important;
} 
  button.home:hover{
    background-color:   var(--ion-color-primary-rgb) !important;
}
.nav-bar li  button.home  ion-label{  
    color: var(--ion-color-primary) !important;
    
 } 
  button.home button{
    border-width: 1px !important;
 }
 button.menu {
  font-size: 1em;
 }
 button.menu.close-menu:hover svg , button.menu.close-menu:hover ion-icon, button.menu.close-menu:hover ion-label{
    color: var(--ion-color-danger) !important;
 }

 .ripple-parent {
    position: relative;
    overflow: hidden; 
    border-radius: 10px;
  }
  
  .rectangle {
    width: 100%;
    height: 100%;
  }
  .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .more-menu-list ion-item{
    --background-hover:  var(--ion-color-primary) !important;
    --color-hover:  var(--ion-color-primary) !important;
    --color-focused:  var(--ion-color-primary) !important;
  } 
  .more-menu-list ion-item:hover ion-label.menu-item{
    --color: var(--ion-color-primary) !important;
  }
  ion-label.menu-item{
    --color: var(--ion-color-primary) !important;
  }
  .more-menu-list ion-label.menu-item{
    font-size: large ;
    display: flex;
    flex-direction:row;
    align-items: center;
    color: var(--ion-color-dark-tint);
  }

  .menu-item{
    --background-hover: rgba(var(--ion-color-primary-rgb) , 1);
  }
  .menu-item:hover {
    --background: #FFF;
    color: var(--ion-color-primary);
    cursor: pointer;
  }
  .menu-item:hover > svg{
    filter: brightness(0) saturate(100%) invert(74%) sepia(24%) saturate(5256%) hue-rotate(345deg) brightness(101%) contrast(106%) !important; 
  }