.popover-date{
    --width: 20rem;
}

.input.date{
    width: 100%;
    border-bottom: 1px solid var(--ion-color-light-tint) !important;
} 

.list{
    background-color: var(--ion-color-light);
    border-bottom: 1px solid var(--ion-color-light-shade);
}

.list.select{
    background-color: var(--ion-color-light-shade);
}
table.app-table{
    width: 100%; 
    border-radius: 10px !important;
}
.app-table tr.app-table-headers {
 border-top-left-radius: 15px; 
 font-size: .8em;
} 
.app-table tr.app-table-headers th.header{ 
    color: var(--ion-color-medium);
}
.app-table tr.app-table-headers th.header[aria-label="first"]{
    border-top-left-radius: 10px !important;
    background-color: var(--ion-color-light-tint) ; 
    border:  1px solid rgba(var(--ion-color-light-tint), .2);
}
.app-table tr.app-table-headers th.header[aria-label="between"]{ 
    background-color: var(--ion-color-light-tint) ; 
}
.app-table tr.app-table-headers th.header[aria-label="last"]{
    border-top-right-radius: 10px !important;
    background-color: var(--ion-color-light-tint) ; 
    border:  1px solid rgba(var(--ion-color-light-tint), .2);
}
.app-table tr.app-table-headers{
    border-top-right-radius: 10px !important;
}
.app-table tr.app-table-row .feild{ padding: .5rem; }
.app-table tr.app-table-row  { 
    border-bottom: 1px solid rgba(var(--ion-color-medium-rgb), .2) ;
    border-left:  1px solid rgba(var(--ion-color-medium-rgb), .2);
    border-right:  1px solid rgba(var(--ion-color-medium-rgb), .2);
}

ion-modal.daterange-modal {    
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    border: 1px solid var(--ion-color-medium);
}


.table-container{
    padding: 1rem;
    background-color: var(--ion-color-light-tint);
     margin-top: 1rem;
    position: relative;
}
.btn-filter-table{
    position:absolute;
    z-index:3 ;
    top: .3rem;
    right: .3rem;
} 
.popup-filter-table::part(content){
    margin-top: -5rem;
}

.table-container.stripes{
    background-color: var(--ion-color-light);
} 
.table-container.stripes .row-body.white{
    background-color: var(--ion-color-light);
}
.table-container.stripes .row-body.light-tint{
    background-color: var(--ion-color-light-tint);
}

.select-multiple-popover{ 
    height: 100%;
    display: flex;
    align-items: center;
    overflow: scroll;
}
.select-multiple-popover::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
} 
.select-multiple-popover .selected{
    min-width: fit-content;
    max-width: 8rem;
    background-color: var(--ion-color-light-tint);
    height: fit-content;
    padding: .4rem;
    border-radius: 7px;
    font-size: .8em;
    margin-right: .5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    z-index: 999;
}
.select-multiple-popover .selected ion-label{
    margin-right: .3rem;
}
.select-multiple-popover .selected ion-icon{
    color: rgba( var(--ion-color-dark), .5);
}
.select-multiple-popover .selected:hover ion-icon{
    color: var(--ion-color-primary) ;
}

.select-multiple-popover{ 
    padding-bottom: 0px !important;
}


.custom-notification{
    background-color: var(--ion-color-light);
    border-radius: 10px;
    padding: .5rem;
    -webkit-box-shadow: 0px 7px 31px -10px rgba(0,0,0,0.43);
    -moz-box-shadow: 0px 7px 31px -10px rgba(0,0,0,0.43);
    box-shadow: 0px 7px 31px -10px rgba(0,0,0,0.43);
    font-size: small;
    width: fit-content !important;
    transition-duration: 300ms;
    position: relative;
}
.custom-notification .closd{
    position: absolute  ;
    margin-top: .5rem ;
    right: .5rem ;
    z-index: 3;
}

 
 
  @keyframes slide-bck-bottom{
    0% {
        -webkit-transform: translateY(7vh);
        transform: translateY(7vh);
    }
    100% {
        -webkit-transform: translateY(-18vh);
        transform: translateY(-18vh);
    }
  }
  @-webkit-keyframes slide-bck-bottom{
    0% {
        -webkit-transform: translateY(7vh);
        transform: translateY(7vh);
    }
    100% {
        -webkit-transform: translateY(-18vh);
        transform: translateY(-18vh);
    }
  }
 
  @keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(7vh);
        transform: translateY(7vh);
    }
  }
  @-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(7vh);
        transform: translateY(7vh);
    }
  }
   

.slide-bottom {
	-webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-bck-bottom {
	-webkit-animation: slide-bck-bottom 0.5s cubic-bezier(0.940, 0.450, 0.460, 0.250) both;
	animation: slide-bck-bottom 0.5s cubic-bezier(0.940, 0.450, 0.460, 0.250) both;
}
.custom-notification .message{
    font-size: .85em;
}
.custom-notification .special-icon-notify{
    width: 2.5rem !important;
    height: 2.5rem !important;
    background-size: cover;
    border-radius: 50%;
}

.drop-down-input{
    font-size: .9em;  
    padding-left: 0px !important;
}
.mdvr-content{
    background-color:  var(--ion-color-light-tint);
    width: 100% ;
    /* height: 100vh ; */
}
#dropdown-input{
    background: var(--ion-color-light-shade) ;
    padding: 0 .2rem 0 .2rem  ;
    border-radius: 5px ;
    position: relative 
}
 
.drop-down-input .dropdown-input{
    background-color: var(--ion-color-light-tint);
    padding-left: .3rem;
    height: 2.5rem !important;
    border-radius: 5px; 
    width: 100%;
}
.drop-down-input .dropdown-input ion-input ,.drop-down-input .dropdown-input ion-input input {
    height: 2.5rem !important;
    --height: 2.5rem !important; 
    --color: var(--ion-color-dark);
    --placeholder-color: var(--ion-color-dark);
}
.drop-down-input .dropdown-input ion-input{
    --color: var(--ion-color-dark);
    --placeholder-color: var(--ion-color-dark);
}
ion-input.input-devicename {
    --color: var(--ion-color-dark);
    --placeholder-color: var(--ion-color-dark);
}
.drop-down-input.focus .dropdown-input{  
    margin: .3rem;
}
.drop-down-input.focus{
    position: absolute;
    width: 100% !important;
    top: -1rem;
    border: 1px solid #EEE;
    
    /* background-color: #FFF; */
    z-index: 9999;
    border-radius: 5px;
    transition: height 500ms;
    box-shadow: 1px 4px 6px 2px rgba(0, 0, 0, 0.03);
}
.drop-down-input.focus[aria-label="false"]{
    top: 0rem !important;
}
.drop-down-input.focus ion-item{ 
    font-size: .9em; font-style: italic; 
    --color: var(--ion-color-dark);
}
.drop-down-input.focus ion-item:hover{
    --background: var(--ion-color-light-tint) !important;
    cursor: pointer;
}
.drop-down-input.focus ion-item.active{ 
    --color: var(--ion-color-dark);
    --background: #F2F2F2 !important;
}




#devices-drop-down-container{
    width: 100% ;
    max-width: 17rem ;
    position:relative ;
    background-color: transparent;
    /* background-color: var(--ion-color-light); */
    padding: .3rem;
    font-size: .9em !important;
    max-height: 15rem; 
}
#devices-drop-down-container .dropdown-input{
    background-color: var(--ion-color-light-shade); 
    width: 100% ;
    border-radius: 5px;
    cursor: pointer;
    display: flex  ;
    flex-direction: row ;
    justify-content: center ;
    align-items: center ; 
    margin-bottom: .3rem;
    padding-left: .5rem;
}   
 
#devices-drop-down-container #devices-drop-down-input{
    display: none;
    min-width:14rem ;
    position: absolute;  
    border: 1px solid #EEE; 
    width: 100% !important;
    overflow-y: scroll;
    border-radius: 5px;
    z-index: 9999 !important;
    float: right;
} 
#devices-drop-down-container:hover > #devices-drop-down-input{
    display: block;  
}
#devices-drop-down-input ion-item{ 
    font-size: .9em !important;
    cursor: pointer;
    align-self: flex-end;
}

.select-popup-content{
    width: 100% ;
    height: 25rem ;
    overflow-y: scroll;
    padding: .5rem ;
    margin-top: .3rem ;
    position: absolute  ;
    z-index:999 ;
    background: #FFF ;
    border: 1px solid #DDD 
}
#timeoute{
    position: fixed  ;
    padding: .3rem 1rem .3rem 1rem ;
    bottom: 0 ;
    right: .3rem ;
    z-Index:99999 ; 
    background: var(--ion-color-light);
    opacity: 0.4;    
    transition-duration: 200ms;
    cursor: pointer;
    border-radius: 5px;
    margin: .3rem;
}
#timeoute:hover{
    opacity:1; 
}