
/*@media only screen and (max-width: 480px) {
    .container-history-mobile{ 
        background-color: var(--ion-color-light);
        height: 50vh !important;
        width: 100vw;
        border-radius: 15px;
        padding: 1rem; 
        position: relative;
    }
    .container-history{ display: none; }
}*/
.container-history-mobile{ 
    background-color: var(--ion-color-light);
    height: 50vh !important;
    width: 100vw;
    border-radius: 15px;
    padding: 1rem; 
    position: relative;
}
.container-history{  height: 80vh !important;}
/* .container-history{ display: none; } */
/* .container-history{ 
    background-color: var(--ion-color-light);
     height: 50vh;
    border-radius: 15px;
    padding: 1rem;
    top: 2rem;
} */
/* @media only screen and (min-width: 720px) {
    
} */

.btn-back-swiper{
    position: absolute;
    z-index: 999;
    left: 1rem;
    top: 2rem;
}

.search-select{
    padding: 0px;
}
.list-devices{ 
    position: absolute;  
    --width:  23rem;
    --height: 50vh; 
    overflow-y: scroll;
    border-radius: 20px ;
    box-shadow: var(--ion-box-shadow);
}
.list-devices.offset-mobile{ 
    --offset-x: 1rem;
    --offset-y: 1rem;
}
.history-list-content{
    width:100%;
    min-height:30vh;
    max-height:70vh;
    overflow-y:scroll;
    border-radius: 15px;
}


/* width */
.list-devices::-webkit-scrollbar, .history-list-content::-webkit-scrollbar {
    width: 0px;
    border-radius: var(--ion-radius);
}  
  /* Track */
.list-devices::-webkit-scrollbar-track , .history-list-content::-webkit-scrollbar-track{
    background:  var(--ion-color-light) ;
    border-radius: var(--ion-radius);
    display: none;
} 
/* Handle */
.list-devices::-webkit-scrollbar-thumb , .history-list-content::-webkit-scrollbar-thumb{
    background: rgba( var(--ion-color-primary-rgb), .3) ;
}
.list-devices .list:hover{
     cursor: pointer;
    --background: var(--ion-color-light-tint);
}


ion-action-sheet[aria-modal="true"]  .action-sheet-group button{ 
    color: var(--ion-color-dark);
}
ion-action-sheet[aria-modal="true"]  .action-sheet-group button.ion-focusable.action-sheet-selected{
    color: var(--ion-color-primary); 
}
 
.card-history{
    width: 100%;
    min-height: 5rem;
    background-color: var(--ion-color-light-tint);
    padding: .5rem;
}
.one{
    border-radius: 15px;
}
.top{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom: 1px dashed var(--ion-color-medium);
}
.bottom{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.normal{
    border-bottom: 1px dashed var(--ion-color-medium);  
}
ion-checkbox.check-route{
    border-radius: 6px;
    --background-checked:  var(--ion-color-secondary);
}
ion-checkbox.check-route::part(container) {
    border-radius: 6px;
    border: 2px solid  var(--ion-color-secondary);
}
.card-history .card-title{
    font-size: .85em; 
    font-weight: bold;
}
.card-history .card-title sub{
    font-weight: 400;
    margin-top: .5rem; 
}

.card-sub-title ,.card-sub-det  { 
    font-size: .8em; 
}
 
.popover-color{
    --width: fit-content; 
    --backdrop-opacity:0.01; 
}
.popover-color #ion-react-wrapper{ 
    border-radius: var(--ion-radius);
    
}

.route-list-container{
    width: 100%;
    height: 32rem !important;
    border-radius: 10px;
    background: var(--ion-color-light-tint);
    margin-top: 1rem;
    padding: .5rem;
    box-shadow: 7px 7px 18px 0px rgba(var(--ion-color-dark-rgb), 0.05) inset;
    overflow-x: scroll;
}
.route-list-container .route-list{
    border-radius: 10px;
    background: var(--ion-color-light);
    margin-bottom: .5rem;
    font-size: small;
}
.route-list .status-name{
    font-weight: bold;
}
.route-list .status-time{
    font-size: smaller;
}
.route-list .address{
    overflow-x: scroll;
    max-height: 3rem;
    font-size: smaller;
}

.route-list.current{
    border: 1px solid var(--ion-color-warning);
    box-shadow: -7px 3px 30px -11px rgba(var(--ion-color-warning-rgb),.5) !important;
}